import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {
  CardHeader,
  CardContent,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Card,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PlusOne, Edit, Delete } from '@mui/icons-material';
import {
  createCoupons,
  updateCoupons,
  deleteCoupons,
} from '../../features/coupons/couponsThunk';
import {
  selectCoupons,
  selectCouponsStatus,
} from '../../features/coupons/couponsSlice';

const INITIAL_FORM_STATE = {
  code: '',
  type: 'percentage',
  value: '',
  isOneTimeUse: false,
  expiresAt: '',
  isActive: true,
};

// Common text field style
const textFieldStyle = {
  '& .MuiInputLabel-root': {
    right: 0,
    left: 'auto',
    transformOrigin: 'right',
  },
  '& .MuiInputBase-root': {
    marginTop: '16px',
  },
  '& .MuiInputBase-input': {
    textAlign: 'right',
  },
};

const MobileRecord = ({
  coupon,
  handleEdit,
  handleDelete,
  formatExpirationDate,
}) => (
  <Card sx={{ mb: 2, p: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button size="small" onClick={() => handleEdit(coupon)}>
          <Edit />
        </Button>
        <Button size="small" onClick={() => handleDelete(coupon.id)}>
          <Delete />
        </Button>
      </Box>
      <span
        style={{
          padding: '4px 8px',
          borderRadius: '4px',
          backgroundColor: coupon.isActive ? '#e8f5e9' : '#ffebee',
          color: coupon.isActive ? '#388e3c' : '#d32f2f',
        }}
      >
        {coupon.isActive ? 'פעיל' : 'לא פעיל'}
      </span>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="text-gray-600">קוד:</span>
        <span>{coupon.code}</span>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="text-gray-600">סוג הנחה:</span>
        <span>{coupon.type === 'percentage' ? 'אחוזים' : 'סכום קבוע'}</span>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="text-gray-600">ערך:</span>
        <span>
          {coupon.type === 'percentage'
            ? `${coupon.value}%`
            : `₪${coupon.value}`}
        </span>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="text-gray-600">תאריך תפוגה:</span>
        <span>{formatExpirationDate(coupon.expiresAt)}</span>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="text-gray-600">שימוש חד פעמי:</span>
        <span>{coupon.isOneTimeUse ? 'כן' : 'לא'}</span>
      </Box>
    </Box>
  </Card>
);

const CouponsManagement = () => {
  const dispatch = useDispatch();
  const coupons = useSelector(selectCoupons);
  const status = useSelector(selectCouponsStatus);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingCoupon, setEditingCoupon] = useState(null);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatExpirationDate = (date) => {
    if (!date) return 'אין תאריך תפוגה';
    const parsedDate = new Date(date);
    return isNaN(parsedDate)
      ? 'תאריך לא חוקי'
      : format(parsedDate, 'dd/MM/yyyy');
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingCoupon) {
        await dispatch(
          updateCoupons({
            id: editingCoupon.id,
            coupon: formData,
          })
        );
      } else {
        await dispatch(createCoupons(formData));
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving coupon:', error);
      // Handle error (show message to user, etc.)
    }
  };

  const handleEdit = (coupon) => {
    setEditingCoupon(coupon);
    const formattedDate = format(new Date(coupon.expiresAt), 'yyyy-MM-dd');
    setFormData({
      code: coupon.code,
      type: coupon.type,
      value: coupon.value,
      isOneTimeUse: coupon.isOneTimeUse,
      expiresAt: formattedDate,
      isActive: coupon.isActive,
    });
    setIsDialogOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק את הקופון הזה?')) {
      try {
        await dispatch(deleteCoupons(id));
      } catch (error) {
        console.error('Error deleting coupon:', error);
        // Handle error (show message to user, etc.)
      }
    }
  };

  const handleOpenNewCoupon = () => {
    setEditingCoupon(null);
    setFormData(INITIAL_FORM_STATE);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setEditingCoupon(null);
    setFormData(INITIAL_FORM_STATE);
  };

  return (
    <div dir="rtl">
      <CardHeader
        dir="ltr"
        action={
          <Button
            startIcon={<PlusOne />}
            variant="contained"
            onClick={handleOpenNewCoupon}
          >
            הוסף קופון
          </Button>
        }
      />
      <CardContent>
        {status === 'loading' ? (
          <div>טוען...</div>
        ) : isMobile ? (
          <Box sx={{ p: 2 }}>
            {coupons.map((coupon) => (
              <MobileRecord
                key={coupon.id}
                coupon={coupon}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                formatExpirationDate={formatExpirationDate}
              />
            ))}
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table dir="rtl">
              <TableHead sx={{ textAlign: 'right' }}>
                <TableRow>
                  <TableCell align="right">קוד</TableCell>
                  <TableCell align="right">סוג הנחה</TableCell>
                  <TableCell align="right">ערך</TableCell>
                  <TableCell align="right">תאריך תפוגה</TableCell>
                  <TableCell align="right">שימוש חד פעמי</TableCell>
                  <TableCell align="right">סטטוס</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coupons.map((coupon) => (
                  <TableRow key={coupon.id}>
                    <TableCell align="right">{coupon.code}</TableCell>
                    <TableCell align="right">
                      {coupon.type === 'percentage' ? 'אחוזים' : 'סכום קבוע'}
                    </TableCell>
                    <TableCell align="right">
                      {coupon.type === 'percentage'
                        ? `${coupon.value}%`
                        : `₪${coupon.value}`}
                    </TableCell>
                    <TableCell align="right">
                      {formatExpirationDate(coupon.expiresAt)}
                    </TableCell>
                    <TableCell align="right">
                      {coupon.isOneTimeUse ? 'כן' : 'לא'}
                    </TableCell>
                    <TableCell align="right">
                      <span
                        style={{
                          padding: '4px 8px',
                          borderRadius: '4px',
                          backgroundColor: coupon.isActive
                            ? '#e8f5e9'
                            : '#ffebee',
                          color: coupon.isActive ? '#388e3c' : '#d32f2f',
                        }}
                      >
                        {coupon.isActive ? 'פעיל' : 'לא פעיל'}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => handleEdit(coupon)}
                      >
                        <Edit />
                      </Button>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => handleDelete(coupon.id)}
                      >
                        <Delete />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        dir="rtl"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingCoupon ? 'עריכת קופון' : 'הוסף קופון חדש'}
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              marginTop: '16px',
            }}
          >
            <TextField
              label="קוד קופון"
              name="code"
              value={formData.code}
              onChange={handleInputChange}
              required
              fullWidth
              variant="standard"
              sx={textFieldStyle}
            />

            <FormControl fullWidth variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel
                id="type-label"
                sx={{
                  right: 0,
                  left: 'auto',
                  transformOrigin: 'right',
                }}
              >
                סוג הנחה
              </InputLabel>
              <Select
                labelId="type-label"
                name="type"
                value={formData.type}
                onChange={handleSelectChange}
                label="סוג הנחה"
                sx={{
                  '& .MuiSelect-select': {
                    paddingRight: '0 !important',
                    paddingLeft: '24px !important',
                    textAlign: 'right',
                  },
                  '& .MuiSelect-icon': {
                    left: '0 !important',
                    right: 'auto !important',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiList-root': {
                        direction: 'rtl',
                      },
                      '& .MuiMenuItem-root': {
                        direction: 'rtl',
                        textAlign: 'right',
                      },
                    },
                  },
                }}
              >
                <MenuItem value="percentage">אחוזים</MenuItem>
                <MenuItem value="fixed">סכום קבוע</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="ערך הנחה"
              name="value"
              type="number"
              value={formData.value}
              onChange={handleInputChange}
              required
              fullWidth
              variant="standard"
              sx={textFieldStyle}
            />

            <TextField
              label="תאריך תפוגה"
              name="expiresAt"
              type="date"
              value={formData.expiresAt}
              onChange={handleInputChange}
              required
              fullWidth
              variant="standard"
              sx={textFieldStyle}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.isOneTimeUse}
                  onChange={handleInputChange}
                  name="isOneTimeUse"
                  color="primary"
                />
              }
              label="שימוש חד פעמי"
              labelPlacement="start"
              sx={{ justifyContent: 'flex-end', marginLeft: 0 }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.isActive}
                  onChange={handleInputChange}
                  name="isActive"
                  color="primary"
                />
              }
              label="פעיל"
              labelPlacement="start"
              sx={{ justifyContent: 'flex-end', marginLeft: 0 }}
            />

            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                ביטול
              </Button>
              <Button type="submit" color="primary">
                {editingCoupon ? 'שמור שינויים' : 'שמור קופון'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CouponsManagement;
