import { createSlice } from '@reduxjs/toolkit';
import { fetchFilters, deleteFilterThunk } from './filterThunk';

export const filterSlice = createSlice({
  name: 'filters',
  initialState: {
    items: [], // Filters will be stored here
    status: 'idle', // Status of the request: idle, loading, succeeded, failed
    error: null, // If there's an error, it will be stored here
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilters.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload; // Update state with the fetched filters
      })
      .addCase(fetchFilters.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteFilterThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteFilterThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = state.items.filter(
          (filter) => filter.id !== action.payload
        ); // Remove the deleted filter
      })
      .addCase(deleteFilterThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Selector to access filters state
export const selectFilters = (state) => state.filters.items;
export const selectFilterStatus = (state) => state.filters.status;

export default filterSlice.reducer;
