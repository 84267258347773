import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCoupons,
  updateCoupon,
  createCoupon,
  deleteCoupon,
} from '../../api/couponsApi';

export const fetchCoupons = createAsyncThunk(
  'coupons/fetchCoupons',
  async () => {
    const response = await getCoupons();
    return response;
  }
);

export const updateCoupons = createAsyncThunk(
  'coupons/updateCoupons',
  async ({ id, coupon }, { rejectWithValue }) => {
    try {
      const response = await updateCoupon(id, coupon);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createCoupons = createAsyncThunk(
  'coupons/createCoupons',
  async (coupon) => {
    const response = await createCoupon(coupon);
    return response;
  }
);

export const deleteCoupons = createAsyncThunk(
  'coupons/deleteCoupons',
  async (id) => {
    const response = await deleteCoupon(id);
    return response;
  }
);
