import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from '../features/auth/authSlice';

export const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleLogout = async () => {
        try {
            await dispatch(logoutUser());
            navigate('/menu');
        } catch (error) {
            console.error('Logout failed:', error);
            // Optionally handle error case
        }
    };
    
    // Return object for consistency with hook patterns
    return { handleLogout };
};
