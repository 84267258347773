import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ordersApi from '../../api/ordersApi';

export const fetchOrderDetails = createAsyncThunk(
  'orders/fetchOrderDetails',
  async (_, { rejectWithValue }) => {
    try {
      return await ordersApi.getOrderDetails();
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchOrderDetailById = createAsyncThunk(
  'orders/fetchOrderDetailById',
  async (id, { rejectWithValue }) => {
    try {
      return await ordersApi.getOrderDetail(id);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchOrderWithDetails = createAsyncThunk(
  'orders/fetchOrderWithDetails',
  async (id, { rejectWithValue }) => {
    try {
      return await ordersApi.getOrderWithDetails(id);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createOrderDetail = createAsyncThunk(
  'orders/createOrderDetail',
  async (orderDetail, { rejectWithValue }) => {
    try {
      return await ordersApi.createOrderDetail(orderDetail);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateOrderDetail = createAsyncThunk(
  'orders/updateOrderDetail',
  async ({ id, orderDetail }, { rejectWithValue }) => {
    try {
      return await ordersApi.updateOrderDetail(id, orderDetail);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteOrderDetail = createAsyncThunk(
  'orders/deleteOrderDetail',
  async (id, { rejectWithValue }) => {
    try {
      await ordersApi.deleteOrderDetail(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);