import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectMenu, selectStatus } from '../features/menu/menuSlice';
import { selectWorkingHours } from '../features/workingHours/workingHoursSlice';
import MenuItem from '../components/MenuItem';
import { Box, Typography, useTheme, Fab, Zoom } from '@mui/material';
import Grid from '@mui/material/Grid2';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Loader from '../components/Loader';
import ResponsiveAppBar from '../components/Navbar';
import ImageTitleHeader from '../components/ImageTitleHeader';
import { useMediaQuery } from '@mui/material';
import SearchInput from '../components/SearchInput';
import CartPopup from '../components/cartPopup';
import Footer from '../components/core/Footer.js';
import { selectConfigurationStatus } from '../features/configuration/configurationSlice';
import { selectWorkingHoursStatus } from '../features/workingHours/workingHoursSlice';
import { alpha } from '@mui/material/styles';

const MenuPage = ({ toggleTheme }) => {
  const menu = useSelector(selectMenu);
  const menuStatus = useSelector(selectStatus);
  const configorationStatus = useSelector(selectConfigurationStatus);
  const workingHoursStatus = useSelector(selectWorkingHoursStatus);
  const workingHours = useSelector(selectWorkingHours);
  const theme = useTheme();
  const [activeCategory, setActiveCategory] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const categoryRefs = useRef({});
  const isScrolling = useRef(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMenu, setFilteredMenu] = useState([]);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    setFilteredMenu(menu);
  }, [menu]);

  useEffect(() => {
    const handleScroll = () => {
      if (isScrolling.current) return;

      const scrollPosition = window.scrollY + window.innerHeight * 0.2;
      let currentCategory = '';

      // Show/hide scroll-to-top button based on scroll position
      setShowScrollTop(window.scrollY > 400);

      Object.entries(categoryRefs.current).forEach(([category, ref]) => {
        if (ref && ref.offsetTop <= scrollPosition) {
          currentCategory = category;
        }
      });

      if (currentCategory !== activeCategory) {
        setActiveCategory(currentCategory);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeCategory]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const formatWorkingHours = (workingHours) => {
    if (!workingHours) return [];

    // Create an array to hold all 7 days (Sunday to Saturday)
    const daysArray = Array(7).fill(null);

    // Loop through workingHours to group shifts by day
    workingHours.forEach((shift) => {
      if (shift.isOpen) {
        const dayIndex = shift.day;

        // If the day already has shifts, add the new shift
        if (daysArray[dayIndex]) {
          daysArray[dayIndex].push({
            openTime: shift.openTime,
            closeTime: shift.closeTime,
          });
        } else {
          // If no shifts exist for the day, create a new array with the shift
          daysArray[dayIndex] = [
            {
              openTime: shift.openTime,
              closeTime: shift.closeTime,
            },
          ];
        }
      }
    });

    return daysArray;
  };

  const scrollToCategory = (categoryId) => {
    const ref = categoryRefs.current[categoryId];
    if (ref) {
      isScrolling.current = true;
      setActiveCategory(categoryId);

      const navbarHeight = 64;
      const targetPosition =
        ref.offsetTop - window.innerHeight * 0.2 + navbarHeight;
      const maxScroll =
        document.documentElement.scrollHeight - window.innerHeight;

      window.scrollTo({
        top: Math.min(targetPosition, maxScroll),
        behavior: 'smooth',
      });

      setTimeout(() => {
        isScrolling.current = false;
      }, 1000);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (term.trim() === '') {
      setFilteredMenu(menu);
    } else {
      const filtered = menu.filter(
        (item) =>
          item.name.toLowerCase().includes(term.toLowerCase()) ||
          item.description.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredMenu(filtered);
    }
  };

  const groupedItems = filteredMenu.reduce((acc, item) => {
    console.log('item', item);
    const category = item.category.id;
    if (!acc[category]) {
      acc[category] = {
        name: item.category.name,
        displayOrder: item.category.displayOrder,
        items: [],
      };
    }
    acc[category].items.push(item);
    return acc;
  }, {});

  Object.values(groupedItems).forEach((category) => {
    category.items.sort((a, b) => a.displayOrder - b.displayOrder);
  });

  const categories = Object.entries(groupedItems).map(([id, category]) => ({
    id: id,
    name: category.name,
    displayOrder: category.displayOrder,
  }));

  const sortedCategories = categories.sort(
    (a, b) => a.displayOrder - b.displayOrder
  );

  if (
    menuStatus === 'loading' ||
    workingHoursStatus === 'loading' ||
    configorationStatus === 'loading'
  ) {
    return <Loader />;
  }

  if (
    menuStatus === 'failed' ||
    workingHoursStatus === 'failed' ||
    configorationStatus === 'failed'
  ) {
    return <div>שגיאה בטעינת האתר.</div>;
  }

  const formattedWorkingHours = formatWorkingHours(workingHours);

  return (
    <Box
      sx={{
        ...theme.customStyles.gradientBackground(theme),
        bgcolor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
        position: 'relative', // Added for absolute positioning of the scroll button
      }}
    >
      <ImageTitleHeader TitleText="אוליבר עגלת קפה" />
      {isMobile && (
        <SearchInput searchTerm={searchTerm} onSearch={handleSearch} />
      )}
      <ResponsiveAppBar
        categories={sortedCategories}
        activeCategory={activeCategory}
        onCategoryClick={scrollToCategory}
        onSearch={handleSearch}
        searchTerm={searchTerm}
      />
      <Box
        sx={{
          padding: theme.spacing(2),
          paddingTop: '0',
        }}
      >
        {filteredMenu.length === 0 && searchTerm !== '' ? (
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              textAlign: 'center',
              color: theme.palette.text.primary,
            }}
          >
            אין מוצר שתואם את החיפוש
          </Typography>
        ) : (
          Object.entries(groupedItems)
            .sort(([, a], [, b]) => a.displayOrder - b.displayOrder)
            .map(([category, categoryData]) => (
              <Box
                key={category}
                ref={(el) => (categoryRefs.current[category] = el)}
                sx={{ marginBottom: theme.spacing(6), textAlign: 'right' }}
              >
                <Typography
                  variant="menuTitle"
                  sx={{
                    textAlign: 'right',
                    marginBottom: theme.spacing(1),
                    color: theme.palette.text.primary,
                  }}
                >
                  {categoryData.name}
                </Typography>
                <Grid container spacing={1} direction="row-reverse">
                  {categoryData.items.map((item) => (
                    <Grid
                      size={{ xs: 12, sm: 6, md: 4, xl: 3 }}
                      key={item.id}
                      sx={{
                        marginTop: theme.spacing(1.5),
                        direction: 'rtl',
                      }}
                    >
                      <MenuItem item={item} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))
        )}
      </Box>
      <Zoom in={showScrollTop}>
        <Fab
          size="small"
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: 90,
            left: 14,
            background: theme.palette.secondary.main,
            color: 'black',
            '&:hover': {
              background: alpha(theme.palette.secondary.main, 0.8),
            },
            zIndex: 1000,
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Zoom>

      <CartPopup />
      {menuStatus === 'succeeded' && (
        <Footer workingHours={formattedWorkingHours} />
      )}
    </Box>
  );
};

export default MenuPage;
