import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MenuItemDash from './MenuItemDash';
import MenuItemPopup from './MenuItemPopUpDash';
import {
  selectManagerMenu,
  selectManagerStatus,
} from '../../features/menu/managerMenuSlice';
import CategoryPopup from './CategoryPopup'; // Import the combined component
import AddIcon from '@mui/icons-material/Add';
import { selectCategories } from '../../features/category/CategorySlice';
import {
  addMenuItem,
  editMenuItem,
} from '../../features/menu/managerMenuThunks';
import {
  addFilter,
  deleteFilterThunk,
  updateFilterThunk,
} from '../../features/filter/filterThunk';
import {
  updateCategoryThunk,
  addCategoryThunk,
  deleteCategoryThunk,
} from '../../features/category/CategoryThunk';
import ConfirmDialog from '../core/confirmDialog';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SpinLoader from '../core/SpinLoader';
import MenuPDFDownload from '../MenuPDFDownload';
import { fetchManagerMenuItems } from '../../features/menu/managerMenuThunks';
import { fetchCategories } from '../../features/category/CategoryThunk';

const MenuItemsDashboard = () => {
  const dispatch = useDispatch();
  const menuItems = useSelector(selectManagerMenu); // Fetch menu items from the Redux store
  const status = useSelector(selectManagerStatus);
  const [categories, setCategories] = useState([]);
  const allCategories = useSelector(selectCategories); // Fetch categories from Redux
  const [isNewItemPopupOpen, setIsNewItemPopupOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const isLoading = useSelector(
    (state) =>
      state.managerMenu.changesPending || state.categories.status === 'loading'
  );

  useEffect(() => {
    if (allCategories.length > 0) {
      const uniqueCategories = allCategories.map((category) => ({
        id: category.id,
        name: category.name,
        displayOrder: category.displayOrder,
      }));
      const sortedCategories = uniqueCategories.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      setCategories(sortedCategories);
    }
  }, [allCategories]);

  const handleCategoriesUpdate = useCallback((updatedCategories) => {
    setCategories(updatedCategories);
  }, []);

  const handleSaveCategories = async (updatedCategories) => {
    await Promise.all(
      updatedCategories.map((category) => {
        if (category.id.startsWith('temp_')) {
          // Dispatch addCategoryThunk for new categories
          return dispatch(addCategoryThunk(category));
        } else {
          // Dispatch updateCategoryThunk for existing categories
          return dispatch(updateCategoryThunk(category));
        }
      })
    );
    dispatch(fetchManagerMenuItems());
    dispatch(fetchCategories());
  };

  const handleDeleteCategory = (category) => {
    setCategoryToDelete(category);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    const result = await dispatch(deleteCategoryThunk(categoryToDelete));
    if (deleteCategoryThunk.fulfilled.match(result)) {
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category.id !== categoryToDelete)
      );
    }
    setCategoryToDelete(null);
    setDeleteConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setCategoryToDelete(null);
  };

  const handleCancelChanges = () => {
    dispatch(fetchManagerMenuItems());
    dispatch(fetchCategories());
  };

  const handleOpenNewItemPopup = () => {
    if (!categories || categories.length === 0) {
      setIsErrorDialogOpen(true);
      return;
    }
    setIsNewItemPopupOpen(true);
  };

  const handleCloseNewItemPopup = () => {
    setIsNewItemPopupOpen(false);
  };

  const handleSaveItem = async (updatedItem, isNewItem, modifiedFilters) => {
    if (isNewItem) {
      // Create a new FormData object without the 'filters' field
      const itemData = new FormData();
      updatedItem.forEach((value, key) => {
        if (key !== 'filters') {
          itemData.append(key, value);
        }
      });

      // Add the new item without filters
      const result = await dispatch(addMenuItem(itemData));

      // Check if the addMenuItem action was successful
      if (addMenuItem.fulfilled.match(result)) {
        const newItemId = result.payload.id; // Assuming the server returns the new item's ID

        // Process modified filters
        for (const [filterId, filterData] of Object.entries(modifiedFilters)) {
          if (filterId.startsWith('temp_')) {
            // This is a new filter, prepare data for create action
            const { id, ...filterDataWithoutId } = filterData;
            // Dispatch create action without the id field
            await dispatch(
              addFilter({ ...filterDataWithoutId, menuItemId: newItemId })
            );
          }
        }
      }
    } else {
      // Update existing item
      const itemId = updatedItem.get('id');
      const itemData = new FormData();
      updatedItem.forEach((value, key) => {
        if (key !== 'filters' && key !== '__v') {
          // Exclude 'filters' and '_v' keys
          itemData.append(key, value);
        }
      });

      // Update the item
      const result = await dispatch(
        editMenuItem({ id: itemId, updatedItem: itemData })
      );

      if (editMenuItem.fulfilled.match(result)) {
        const updatedItemId = result.payload.id;

        // Process filters
        for (const [filterId, filterData] of Object.entries(modifiedFilters)) {
          if (filterData._deleted) {
            if (!filterId.startsWith('temp_')) {
              // Delete existing filter
              await dispatch(deleteFilterThunk(filterId));
            }
          } else if (!filterId.startsWith('temp_')) {
            const { _v, ...filteredData } = filterData;
            await dispatch(
              updateFilterThunk({
                id: filterId,
                updatedFilter: { ...filteredData, menuItemId: updatedItemId },
              })
            );
          }
        }

        // Create new filters
        for (const [filterId, filterData] of Object.entries(modifiedFilters)) {
          if (filterId.startsWith('temp_')) {
            const { id, _deleted, ...filterDataWithoutId } = filterData;
            await dispatch(
              addFilter({ ...filterDataWithoutId, menuItemId: updatedItemId })
            );
          }
        }
      }
    }
    handleCloseNewItemPopup();
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
  };

  // Group menu items by category
  const groupedItems = Array.isArray(categories)
    ? categories.map((category) => {
        const itemsInCategory = menuItems.filter(
          (item) => item.category && item.category.id === category.id
        );
        return {
          ...category,
          items: itemsInCategory, // Associate the items with the category
        };
      })
    : [];

  // Handle loading and error states
  if (status === 'loading') {
    return <SpinLoader />;
  }

  if (status === 'failed') {
    return <div>Failed to load menu items.</div>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      {/* Organize {add new dish},{change category order} */}
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: '2rem',
          justifyContent: 'flex-end',
        }}
      >
        {isLoading && (
          <Grid item xs={12}>
            <SpinLoader />
          </Grid>
        )}

        <Grid item xs={6} md={3}>
          <MenuPDFDownload />
        </Grid>

        <Grid item xs={6} md={3}>
          <CategoryPopup
            categories={Array.isArray(categories) ? categories : []}
            onCategoriesUpdate={handleCategoriesUpdate}
            onSaveCategories={handleSaveCategories}
            onCancelChanges={handleCancelChanges}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenNewItemPopup}
            startIcon={<AddIcon />}
            sx={{
              alignSelf: 'flex-start',
              minWidth: '200px',
              borderRadius: '0.5rem',
              height: '3rem',
              margin: 0, // Remove default margin
            }}
          >
            הוסף מנה חדשה
          </Button>
        </Grid>
      </Grid>

      {groupedItems
        .sort((a, b) => a.displayOrder - b.displayOrder) // Sort categories by displayOrder
        .map((category) => (
          <Box key={category.id} sx={{ marginBottom: 3, textAlign: 'right' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                onClick={() => handleDeleteCategory(category.id)}
                size="small"
                sx={{ color: 'red' }}
              >
                <DeleteForeverIcon />
              </IconButton>
              <Typography variant="menuTitle" sx={{ marginRight: 1 }}>
                {category.name}
              </Typography>
            </Box>
            <Grid container spacing={2} direction="row-reverse">
              {category.items && category.items.length > 0 ? (
                category.items.map((item) => (
                  <Grid
                    size={{ xs: 12, sm: 6, md: 4, xl: 3 }}
                    key={item.id}
                    sx={{ direction: 'rtl' }}
                  >
                    <MenuItemDash item={item} onSaveEditItem={handleSaveItem} />
                  </Grid>
                ))
              ) : (
                <Typography variant="body1">אין מוצרים בקטגוריה זו</Typography>
              )}
            </Grid>
          </Box>
        ))}
      <MenuItemPopup
        open={isNewItemPopupOpen}
        onClose={handleCloseNewItemPopup}
        onSave={handleSaveItem}
        item={{}} // Pass an empty object for a new item
        categories={allCategories} // Pass all categories
      />
      <ConfirmDialog
        open={deleteConfirmOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="האם אתה בטוח שברצונך למחוק קטגוריה זו?"
        description="פעולה זו תמחק את הקטגוריה ואת כל המוצרים שבתוכה לצמיתות. האם אתה בטוח שברצונך להמשיך?"
      />
      <ConfirmDialog
        open={isErrorDialogOpen}
        onClose={handleCloseErrorDialog}
        onConfirm={handleCloseErrorDialog}
        title="לא ניתן להוסיף מנה"
        description="עליך ליצור לפחות קטגוריה אחת לפני הוספת מנה חדשה"
        confirmText="הבנתי"
        isShowDeleteBtn={false}
      />
    </Box>
  );
};

export default MenuItemsDashboard;
