import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

function GenerateFiles() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [summaryData, setSummaryData] = useState([]);

  const fetchSummaryData = async () => {
    const mockData = {
      documents: [
        { type: 100, description: 'חשבונית', quantity: 120, amount: 15000 },
        { type: 305, description: 'חשבונית מס', quantity: 260, amount: 320000 },
      ],
    };
    setSummaryData(mockData.documents);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {/* הוספת CSS מיוחד להדפסה */}
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            #printableTable, #printableTable * {
              visibility: visible;
            }
            #printableTable {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
            }
          }
        `}
      </style>

      <Box sx={{ padding: 4, direction: 'rtl', textAlign: 'right' }}>
        <Typography variant="h5" gutterBottom>
          יצירת מבנה אחיד והצגת טבלה
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, marginBottom: 3, marginTop: 3 }}>
          <TextField
            label="תאריך התחלה"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="תאריך סיום"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            onClick={fetchSummaryData}
            disabled={!startDate || !endDate}
          >
            הצג סיכום
          </Button>
        </Box>

        {summaryData.length > 0 && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginBottom: 2 }}
              onClick={handlePrint}
            >
              הדפס טבלה
            </Button>
            <div id="printableTable">
              <TableContainer component={Paper} sx={{ direction: 'rtl' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">מספר המסמך</TableCell>
                      <TableCell align="right">סוג המסמך</TableCell>
                      <TableCell align="right">סה"כ כמותי</TableCell>
                      <TableCell align="right">סה"כ כספי (בש"ח)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {summaryData.map((doc, index) => (
                      <TableRow key={index}>
                        <TableCell align="right">{doc.type}</TableCell>
                        <TableCell align="right">{doc.description}</TableCell>
                        <TableCell align="right">{doc.quantity}</TableCell>
                        <TableCell align="right">
                          {doc.amount.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </Box>
    </>
  );
}

export default GenerateFiles;
