import { createSlice } from '@reduxjs/toolkit';
import { fetchConfiguration, updateConfigurations } from './configurationThunk';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfiguration: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfiguration.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConfiguration.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchConfiguration.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateConfigurations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateConfigurations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle both single object and array responses
        const updatedConfigs = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];
        updatedConfigs.forEach((updatedConfig) => {
          const index = state.items.findIndex(
            (item) => item.id === updatedConfig.id
          );
          if (index !== -1) {
            state.items[index] = updatedConfig;
          }
        });
      })
      .addCase(updateConfigurations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setConfiguration } = configurationSlice.actions;

export const selectConfiguration = (state) => state.configuration.items;
export const selectConfigurationStatus = (state) => state.configuration.status;

export { fetchConfiguration, updateConfigurations }; // Re-export thunk actions

export default configurationSlice.reducer;
