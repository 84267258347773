import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Typography,
  Alert,
  CircularProgress,
  TextField,
  Paper,
  Container,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { loginUser, clearError } from '../features/auth/authSlice';
import { styled } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    transformOrigin: 'right !important',
    left: 'inherit !important',
    right: '0rem !important',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: '1.1rem',
    overflow: 'unset',
    direction: 'rtl',
  },
  '& label.Mui-focused': {
    right: '0 !important',
    color: theme.palette.primary.main,
    direction: 'rtl',
  },
  '& .MuiInput-underline:before': {
    borderBottomWidth: '2px',
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomWidth: '2px',
  },
  '& .MuiInput-underline:after': {
    borderBottomWidth: '2px',
  },
  '& input': {
    textAlign: 'right',
    color: theme.palette.text.primary,
    fontSize: '1.1rem',
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right',
    color: theme.palette.error.main,
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: '14px',
  borderRadius: '12px',
  fontSize: '1.1rem',
  fontWeight: 600,
  textTransform: 'none',
  background:
    theme.palette.mode === 'dark'
      ? `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
      : theme.palette.primary.main,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 4px 15px rgba(0, 0, 0, 0.4)'
      : '0 4px 15px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 6px 20px rgba(0, 0, 0, 0.6)'
        : '0 6px 20px rgba(0, 0, 0, 0.15)',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  borderRadius: 24,
  position: 'relative',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 8px 32px rgba(0, 0, 0, 0.4)'
      : '0 8px 32px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(20px)',
  background:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(37, 37, 37, 0.9), rgba(28, 28, 28, 0.8))'
      : 'linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8))',
  border: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.05)'
      : 'rgba(255, 255, 255, 0.5)',
  width: '100%',
  maxWidth: '400px',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 4px 20px rgba(0, 0, 0, 0.5)'
      : '0 4px 20px rgba(0, 0, 0, 0.15)',
  animation: 'float 3s ease-in-out infinite',
  '@keyframes float': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-10px)',
    },
  },
}));

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { status, error, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      const from = location.state?.from?.pathname || '/dashboard';
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(loginUser({ username, password })).unwrap();
    } catch (err) {
      // Error handling is managed by the slice
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        ...theme.customStyles.gradientBackground(theme),
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            theme.palette.mode === 'dark'
              ? 'radial-gradient(circle at 50% 50%, rgba(25, 118, 210, 0.1), transparent 70%)'
              : 'radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.8), transparent 70%)',
          animation: 'pulse 15s infinite',
        },
        '@keyframes pulse': {
          '0%': {
            opacity: 0.5,
          },
          '50%': {
            opacity: 1,
          },
          '100%': {
            opacity: 0.5,
          },
        },
      }}
    >
      <StyledPaper elevation={0}>
        <IconWrapper>
          <LockOutlinedIcon sx={{ color: 'white' }} />
        </IconWrapper>

        <Typography
          variant="menuTitle"
          sx={{
            mb: 4,
            fontWeight: 700,
            color: theme.palette.primary.main,
            textShadow:
              theme.palette.mode === 'dark'
                ? '0 2px 4px rgba(0,0,0,0.5)'
                : 'none',
          }}
        >
          התחברות לניהול תפריט
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <CustomTextField
            label="שם משתמש"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="standard"
            required
            fullWidth
            disabled={status === 'loading'}
          />

          <CustomTextField
            label="סיסמה"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="standard"
            required
            fullWidth
            disabled={status === 'loading'}
          />

          <StyledButton
            type="submit"
            variant="contained"
            fullWidth
            disabled={status === 'loading'}
          >
            {status === 'loading' ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'התחבר'
            )}
          </StyledButton>

          {error && (
            <Alert
              severity="error"
              sx={{
                mt: 2,
                borderRadius: 2,
                backgroundColor: 'error.light',
                color: 'white',
                direction: 'rtl',
                fontWeight: '500',
                fontSize: '1.1rem',
                alignItems: 'center',
                '& .MuiAlert-icon': {
                  marginRight: 0,
                  marginLeft: 1,
                  color: 'white',
                },
              }}
            >
              {error === 'Network Error' ? 'שגיאת תקשורת' : error}
            </Alert>
          )}
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default Login;
