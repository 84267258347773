import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { selectMenu } from '../features/menu/managerMenuSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import HebrewFontLightUrl from '../assets/font/NotoSansHebrew-Light.ttf';
import HebrewFontRegularUrl from '../assets/font/NotoSansHebrew-Regular.ttf';
import HebrewFontMediumUrl from '../assets/font/NotoSansHebrew-Medium.ttf';
import HebrewFontSemiBoldUrl from '../assets/font/NotoSansHebrew-SemiBold.ttf';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// הרשמת הפונטים העבריים
Font.register({
  family: 'NotoSansHebrew',
  fonts: [
    { src: HebrewFontLightUrl, fontWeight: 300 },
    { src: HebrewFontRegularUrl, fontWeight: 400 },
    { src: HebrewFontMediumUrl, fontWeight: 500 },
    { src: HebrewFontSemiBoldUrl, fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 30,
    fontFamily: 'NotoSansHebrew',
    fontWeight: 400, // Regular as default
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: 600, // SemiBold
    textAlign: 'center',
    marginBottom: 20,
  },
  category: {
    fontSize: 18,
    fontWeight: 500, // Medium
    marginBottom: 10,
    marginTop: 20,
    textAlign: 'right',
  },
  item: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  itemNameContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  itemName: {
    fontSize: 12,
    fontWeight: 400, // Regular
    textAlign: 'right',
  },
  itemSubtitle: {
    fontSize: 10,
    fontWeight: 300, // Light
    textAlign: 'right',
    color: '#666',
  },
  itemPrice: {
    fontSize: 12,
    fontWeight: 300, // Light
    textAlign: 'left',
  },
});

const MenuPDF = ({ sortedCategories }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>תפריט אוליבר עגלת קפה</Text>
        {sortedCategories.map((category) => (
          <View key={category.name}>
            <Text style={styles.category}>{category.name}</Text>
            {category.items
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((item) => (
                <View style={styles.item} key={item.id}>
                  <View style={styles.itemNameContainer}>
                    <Text style={styles.itemName}>{item.name}</Text>
                    {item.description && (
                      <Text style={styles.itemSubtitle}>
                        {item.description}
                      </Text>
                    )}
                  </View>
                  <Text style={styles.itemPrice}>{`₪${item.price}`}</Text>
                </View>
              ))}
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

const MenuPDFDownload = () => {
  const theme = useTheme();
  const menu = useSelector(selectMenu);

  const buttonBackground = theme.palette.secondary.main;

  const groupedItems = menu.reduce((acc, item) => {
    const category = item.category.id;
    if (!acc[category]) {
      acc[category] = {
        name: item.category.name,
        displayOrder: item.category.displayOrder,
        items: [],
      };
    }
    acc[category].items.push(item);
    return acc;
  }, {});

  const sortedCategories = Object.values(groupedItems).sort(
    (a, b) => a.displayOrder - b.displayOrder
  );
  return (
    <PDFDownloadLink
      document={<MenuPDF sortedCategories={sortedCategories} />}
      fileName="oliver-coffee-menu.pdf"
      style={{
        display: 'grid',
        alignItems: 'center',
        backgroundColor: buttonBackground,
        padding: '0.5rem 1rem',
        color: 'black',
        borderRadius: '0.5rem',
        cursor: 'pointer',
        textDecoration: 'none',
        minWidth: '200px',
        textAlign: 'center',
        height: '3rem',
        margin: 0, // Remove default margin
      }}
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <span style={{ fontSize: '26px' }}>...</span>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center items
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon icon={faFilePdf} size="1x" />
            <Typography
              sx={{ marginLeft: '1rem', fontWeight: 600, fontSize: '0.875rem' }}
            >
              הורד תפריט
            </Typography>
          </Box>
        )
      }
    </PDFDownloadLink>
  );
};

export default MenuPDFDownload;
