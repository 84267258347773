import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

const Textarea = ({
  field,
  updateFieldLabel,
  onDeleteField,
  onToggleRequired,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box
      mb={2}
      sx={{
        position: 'relative',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        padding: 2,
      }}
    >
      <IconButton
        size="small"
        onClick={() => onDeleteField(field.id)}
        sx={{
          position: 'absolute',
          top: 8,
          left: 8,
          color: 'error.main',
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <Box display="flex" alignItems="center" mb={1} justifyContent="flex-end">
        <FormControlLabel
          control={
            <Switch
              checked={field.required || false}
              onChange={(e) => onToggleRequired(field.id, e.target.checked)}
              size="small"
            />
          }
          label="חובה"
          sx={{ ml: 0, mr: 2 }}
          labelPlacement="start"
        />
        {isEditing ? (
          <TextField
            value={field.label}
            onChange={(e) => updateFieldLabel(field.id, e.target.value)}
            onBlur={() => setIsEditing(false)}
            autoFocus
            size="small"
            sx={{
              '& .MuiInputBase-input': { textAlign: 'right' },
            }}
          />
        ) : (
          <>
            <IconButton size="small" onClick={() => setIsEditing(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <Typography
              variant="subtitle1"
              color={field.required ? 'error.main' : 'inherit'}
              sx={{ textAlign: 'right' }}
            >
              {field.required && (
                <ErrorIcon sx={{ ml: 1, fontSize: 20, color: 'error.main' }} />
              )}
              {field.label}
            </Typography>
          </>
        )}
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        multiline
        rows={4}
        required={field.required}
        sx={{
          '& .MuiInputBase-input': { textAlign: 'right' },
        }}
        InputProps={{
          placeholder: field.label,
        }}
      />
    </Box>
  );
};

export default Textarea;
