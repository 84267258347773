import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMenuItems,
  createMenuItem,
  updateMenuItem,
  deleteMenuItem,
} from '../../api/menuApi';

// Fetch all menu items
export const fetchManagerMenuItems = createAsyncThunk(
  'managerMenu/fetchManagerMenuItems',
  async () => {
    const response = await getMenuItems();
    return response;
  }
);

// Create a new menu item
export const addMenuItem = createAsyncThunk(
  'managerMenu/addMenuItem',
  async (newItem) => {
    // Transform the FormData to use categoryId instead of category
    const formData = new FormData();
    newItem.forEach((value, key) => {
      if (key === 'category') {
        formData.append('categoryId', value);
      } else if (key !== 'filters') {
        formData.append(key, value);
      }
    });
    const response = await createMenuItem(formData);
    return response;
  }
);

export const editMenuItem = createAsyncThunk(
  'managerMenu/editMenuItem',
  async ({ id, updatedItem }) => {
    // Transform the FormData to use categoryId instead of category
    const formData = new FormData();
    updatedItem.forEach((value, key) => {
      if (key === 'category') {
        formData.append('categoryId', value);
      } else if (key !== 'filters' && key !== '__v') {
        formData.append(key, value);
      }
    });
    const response = await updateMenuItem(id, formData);
    return response;
  }
);

// Delete a menu item
export const removeMenuItem = createAsyncThunk(
  'managerMenu/removeMenuItem',
  async (id) => {
    const response = await deleteMenuItem(id);
    return response === true ? id : null;
  }
);
