import React from 'react';
import {
  Box,
  Skeleton,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { alpha } from '@mui/material/styles';

const Loader = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // Get the background color based on theme mode
  const skeletonBgColor = isDarkMode
    ? alpha(theme.palette.grey[900], 0.5)
    : alpha(theme.palette.grey[200], 0.8);

  // Get the skeleton animation color based on theme mode
  const skeletonColor = isDarkMode
    ? alpha(theme.palette.grey[800], 0.5)
    : alpha(theme.palette.grey[300], 0.8);

  return (
    <>
      {/* Header Skeleton */}
      <Box
        height="30vw"
        maxHeight={'300px'}
        sx={{
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          direction: 'rtl',
          bgcolor: skeletonBgColor,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            animation="wave"
            sx={{ bgcolor: skeletonColor }}
          />
        </Box>
        <Typography
          component="h1"
          sx={{
            fontSize: '3rem',
            fontWeight: 'bold',
            position: 'absolute',
            top: 'calc(20% + 3vw)',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            padding: '1rem',
            textAlign: 'center',
          }}
        >
          <Skeleton
            width="60%"
            sx={{ bgcolor: skeletonColor, margin: '0 auto' }}
          />
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(65% + -1vw)',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Skeleton width="15%" sx={{ bgcolor: skeletonColor }} />
          <Skeleton width="15%" sx={{ bgcolor: skeletonColor }} />
        </Box>
      </Box>

      {/* Content Sections */}
      <Box
        sx={{ width: '100%', bgcolor: theme.palette.background.default }}
        dir="rtl"
      >
        {/* Navigation Skeleton */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 0,
            bgcolor: skeletonBgColor,
            width: '100%',
            padding: 3,
            direction: 'ltr',
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <Skeleton
              key={item}
              variant="text"
              width={100}
              sx={{ ml: 1, bgcolor: skeletonColor }}
            />
          ))}
        </Box>

        {/* Menu Items Sections */}
        <Box sx={{ padding: 2 }}>
          {/* Sections (Pizza, Drinks, etc.) */}
          {[6, 6, 6].map((itemCount, sectionIndex) => (
            <Box key={sectionIndex} sx={{ mb: 4 }}>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                <Skeleton width={120} sx={{ bgcolor: skeletonColor }} />
              </Typography>
              <Grid container spacing={2}>
                {Array.from({ length: itemCount }).map((_, index) => (
                  <Grid size={{ xs: 12, sm: 6, md: 4, xl: 3 }} key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        bgcolor: skeletonBgColor,
                        borderRadius: '1rem',
                        overflow: 'hidden',
                      }}
                    >
                      <Box sx={{ width: '100%', ml: 2, mr: 1, mt: 1 }}>
                        <Skeleton width="30%" sx={{ bgcolor: skeletonColor }} />
                        <Skeleton width="70%" sx={{ bgcolor: skeletonColor }} />
                        <Box
                          sx={{
                            width: '100%',
                            mt: 3,
                            alignSelf: 'flex-end',
                            textAlign: 'right',
                          }}
                        >
                          <Skeleton
                            width="10%"
                            sx={{ bgcolor: skeletonColor }}
                          />
                        </Box>
                      </Box>
                      <Skeleton
                        variant="rounded"
                        width={300}
                        height={112}
                        sx={{
                          borderRadius: '1rem',
                          bgcolor: skeletonColor,
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Box>

        {/* Footer Skeleton */}
        <Box
          component="footer"
          sx={{
            bgcolor: skeletonBgColor,
            pt: 1,
            mt: 'auto',
            direction: 'ltr',
          }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Box sx={{ width: '100%' }}>
              <Container>
                <Grid container spacing={3} direction="row-reverse">
                  {/* Contact Info Skeleton */}
                  <Grid
                    size={{ xs: 12, md: 3 }}
                    sx={{ textAlign: 'right', position: 'relative' }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Skeleton width={100} sx={{ bgcolor: skeletonColor }} />
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {[1, 2, 3].map((item) => (
                        <Box key={item} sx={{ display: 'flex', gap: 1 }}>
                          <Skeleton
                            width={120}
                            sx={{ bgcolor: skeletonColor }}
                          />
                          <Skeleton
                            variant="circular"
                            width={24}
                            height={24}
                            sx={{ bgcolor: skeletonColor }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>

                  {/* Opening Hours Skeleton */}
                  <Grid size={{ xs: 12, md: 3 }} sx={{ textAlign: 'right' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        textAlign: 'right',
                        display: 'flex !important',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Skeleton width={120} sx={{ bgcolor: skeletonColor }} />
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'flex-end',
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                        <Box key={item}>
                          <Skeleton
                            width={150}
                            sx={{ bgcolor: skeletonColor }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>

                  {/* Social Links Skeleton */}
                  <Grid size={{ xs: 12, md: 3 }} sx={{ textAlign: 'right' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Skeleton width={110} sx={{ bgcolor: skeletonColor }} />
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Skeleton
                        variant="circular"
                        width={40}
                        height={40}
                        sx={{ bgcolor: skeletonColor }}
                      />
                      <Skeleton
                        variant="circular"
                        width={40}
                        height={40}
                        sx={{ bgcolor: skeletonColor }}
                      />
                    </Box>
                  </Grid>

                  {/* Essential Links Skeleton */}
                  <Grid size={{ xs: 12, md: 3 }} sx={{ textAlign: 'right' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        textAlign: 'right',
                        display: 'flex !important',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Skeleton width={120} sx={{ bgcolor: skeletonColor }} />
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'flex-end',
                      }}
                    >
                      {[1, 2, 3].map((item) => (
                        <Box key={item}>
                          <Skeleton
                            width={150}
                            sx={{ bgcolor: skeletonColor }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>

            {/* Desktop Logo Divider */}
            {isDesktop && (
              <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                <Skeleton
                  variant="rectangular"
                  width={2}
                  height="90%"
                  sx={{ bgcolor: skeletonColor }}
                />
                <Box sx={{ ml: 1 }}>
                  <Skeleton
                    variant="rectangular"
                    width={160}
                    height={160}
                    sx={{ bgcolor: skeletonColor, marginLeft: '1rem' }}
                  />
                </Box>
              </Box>
            )}
          </Box>

          {/* Bottom Bar Skeleton */}
          <Box
            sx={{
              bgcolor: skeletonBgColor,
              textAlign: 'center',
              mt: 2,
              py: 1,
            }}
          >
            <Skeleton
              width={200}
              sx={{
                margin: '0 auto',
                bgcolor: skeletonColor,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Loader;
