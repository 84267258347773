import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Divider,
  TextField,
  MenuItem,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  LinearProgress,
  Alert,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Download as DownloadIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
  Assessment as AssessmentIcon,
  TableChart as TableChartIcon,
} from '@mui/icons-material';
import * as XLSX from 'xlsx';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import DeletionManager from '../EmployeeFormComponenents/DeletionManager';
import {
  selectForms,
  selectSubmissions,
  selectFormsLoading,
  selectFormsError,
} from '../../features/EmployeeForms/formsSlice';
import {
  loadFormsData,
  deleteSubmission,
} from '../../features/EmployeeForms/formThunk';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const FormSubmissions = () => {
  const dispatch = useDispatch();

  // Redux state
  const forms = useSelector(selectForms);
  const submissions = useSelector(selectSubmissions);
  const isLoading = useSelector(selectFormsLoading);
  const error = useSelector(selectFormsError);

  const [filteredSubmissions, setFilteredSubmissions] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [sortBy, setSortBy] = React.useState('date');
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [selectedFormId, setSelectedFormId] = React.useState('all');
  const [statsDialogOpen, setStatsDialogOpen] = React.useState(false);
  const [currentStats, setCurrentStats] = React.useState(null);
  const [currentView, setCurrentView] = React.useState(0);

  useEffect(() => {
    dispatch(loadFormsData());
  }, [dispatch]);

  const filterAndSortSubmissions = useCallback(() => {
    let result = [...submissions];

    if (selectedFormId !== 'all') {
      result = result.filter((sub) => sub.formId.toString() === selectedFormId);
    }

    if (searchQuery) {
      result = result.filter((sub) =>
        sub.formTitle.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    result.sort((a, b) => {
      let comparison = 0;
      switch (sortBy) {
        case 'title':
          comparison = a.formTitle.localeCompare(b.formTitle);
          break;
        case 'date':
        default:
          comparison = new Date(b.submittedAt) - new Date(a.submittedAt);
      }
      return sortOrder === 'asc' ? comparison : -comparison;
    });

    setFilteredSubmissions(result);
  }, [submissions, searchQuery, sortBy, sortOrder, selectedFormId]);

  useEffect(() => {
    filterAndSortSubmissions();
  }, [filterAndSortSubmissions]);

  const handleDeleteSubmission = async (submission) => {
    try {
      await dispatch(deleteSubmission(submission.id)).unwrap();
      return true;
    } catch (error) {
      console.error('Error deleting submission:', error);
      throw new Error('Failed to delete submission');
    }
  };

  const exportToExcel = useCallback(
    (specificSubmissions) => {
      try {
        const dataToExport = (specificSubmissions || filteredSubmissions).map(
          (submission) => {
            const form = forms.find((f) => f.id === submission.formId);
            const formattedAnswers = {};

            if (form) {
              form.fields.forEach((field) => {
                const answer = submission.answers[field.id];
                formattedAnswers[field.label] = Array.isArray(answer)
                  ? answer.join(', ')
                  : answer;
              });
            }

            return {
              'Form Title': submission.formTitle,
              'Submitted At': new Date(submission.submittedAt).toLocaleString(),
              ...formattedAnswers,
            };
          }
        );

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Submissions');
        XLSX.writeFile(wb, 'form_submissions.xlsx');
      } catch (error) {
        console.error('Error exporting to Excel:', error);
      }
    },
    [filteredSubmissions, forms]
  );

  const calculateStats = useCallback(
    (formId) => {
      const form = forms.find((f) => f.id === formId);
      const formSubmissions = submissions.filter((s) => s.formId === formId);

      if (!form || !form.fields || !formSubmissions.length) return null;

      const stats = {
        totalSubmissions: formSubmissions.length,
        questions: form.fields
          .filter((field) => field.type !== 'email')
          .map((field) => {
            const answers = formSubmissions.map((s) => s.answers[field.id]);

            if (field.type === 'text') {
              return {
                question: field.label,
                type: field.type,
                responseCount: answers.filter((a) => a && a.trim()).length,
              };
            } else {
              const optionCounts = {};
              field.options?.forEach((option) => {
                optionCounts[option] = answers.filter((a) =>
                  field.type === 'multiple'
                    ? Array.isArray(a) && a.includes(option)
                    : a === option
                ).length;
              });

              return {
                question: field.label,
                type: field.type,
                options: optionCounts,
              };
            }
          }),
      };

      setCurrentStats(stats);
      setStatsDialogOpen(true);
    },
    [forms, submissions]
  );

  const renderStats = () => {
    if (!currentStats) return null;

    return (
      <Box sx={{ mt: 2 }}>
        {currentStats.questions.map((questionStat, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              {questionStat.question}
            </Typography>

            {questionStat.type === 'text' ? (
              <Typography>
                אחוז מענה:{' '}
                {(
                  (questionStat.responseCount / currentStats.totalSubmissions) *
                  100
                ).toFixed(1)}
                %
              </Typography>
            ) : (
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  {questionStat.type === 'radio' ? (
                    <PieChart>
                      <Pie
                        data={Object.entries(questionStat.options).map(
                          ([name, value]) => ({
                            name,
                            value,
                          })
                        )}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, percent }) =>
                          `${name} (${(percent * 100).toFixed(0)}%)`
                        }
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {Object.entries(questionStat.options).map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          )
                        )}
                      </Pie>
                      <RechartsTooltip />
                    </PieChart>
                  ) : (
                    <BarChart
                      data={Object.entries(questionStat.options).map(
                        ([name, value]) => ({
                          name,
                          value,
                        })
                      )}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <RechartsTooltip />
                      <Bar dataKey="value" fill="#8884d8">
                        {Object.entries(questionStat.options).map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          )
                        )}
                      </Bar>
                    </BarChart>
                  )}
                </ResponsiveContainer>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const renderAnswers = (answers, formId) => {
    const form = forms.find((f) => f.id === formId);
    if (!form) return null;

    return form.fields.map((field, index) => (
      <Box key={field.id} mb={2}>
        <Typography variant="subtitle2" color="text.secondary">
          שאלה {index + 1}: {field.label}
        </Typography>
        <Typography variant="body1">
          {field.type === 'multiple'
            ? Array.isArray(answers[field.id])
              ? answers[field.id].join(', ')
              : 'אין תשובה'
            : answers[field.id] || 'אין תשובה'}
        </Typography>
      </Box>
    ));
  };

  const renderSubmissionsList = () => (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ mb: 3, direction: 'rtl' }}
      >
        <TextField
          select
          label="בחר טופס"
          value={selectedFormId}
          onChange={(e) => setSelectedFormId(e.target.value)}
          size="small"
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="all">כל הטפסים</MenuItem>
          {forms.map((form) => (
            <MenuItem key={form.id} value={form.id.toString()}>
              {form.title}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="חיפוש בהגשות"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
            ),
          }}
          sx={{ flexGrow: 1 }}
        />

        <TextField
          select
          label="מיון לפי"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="date">תאריך הגשה</MenuItem>
          <MenuItem value="title">כותרת הטופס</MenuItem>
        </TextField>

        <TextField
          select
          label="סדר"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          size="small"
          sx={{ minWidth: 100 }}
        >
          <MenuItem value="desc">יורד</MenuItem>
          <MenuItem value="asc">עולה</MenuItem>
        </TextField>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 3, display: 'flex', justifyContent: 'right' }}
      >
        {selectedFormId !== 'all' && (
          <Button
            variant="contained"
            startIcon={<AssessmentIcon />}
            onClick={() => calculateStats(parseInt(selectedFormId))}
            disabled={!filteredSubmissions.length}
          >
            צפה בסטטיסטיקות
          </Button>
        )}
        <Button
          variant="contained"
          startIcon={<TableChartIcon />}
          onClick={() => exportToExcel()}
          disabled={!filteredSubmissions.length}
        >
          ייצוא לאקסל
        </Button>
      </Stack>

      {filteredSubmissions.length === 0 ? (
        <Typography
          variant="body1"
          color="text.secondary"
          textAlign="center"
          mt={4}
        >
          {searchQuery
            ? 'לא נמצאו הגשות התואמות את החיפוש.'
            : 'אין הגשות טפסים עדיין.'}
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {filteredSubmissions.map((submission) => (
            <Grid item xs={12} key={submission.id}>
              <Accordion sx={{ direction: 'rtl' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`submission-${submission.id}-content`}
                  id={`submission-${submission.id}-header`}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      pr: 2,
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        {submission.formTitle}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        הוגש:{' '}
                        {new Date(submission.submittedAt).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box>
                      <DeletionManager
                        item={submission}
                        title={`מחיקת הגשה`}
                        description="האם אתה בטוח שברצונך למחוק הגשה זו? פעולה זו אינה הפיכה."
                        onDelete={handleDeleteSubmission}
                      >
                        <IconButton size="small">
                          <DeleteIcon />
                        </IconButton>
                      </DeletionManager>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          exportToExcel([submission]);
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider sx={{ mb: 2 }} />
                  {renderAnswers(submission.answers, submission.formId)}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );

  return (
    <Box sx={{ p: 3 }}>
      {isLoading && <LinearProgress sx={{ mb: 2 }} />}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mb: 3,
          direction: 'rtl',
        }}
      >
        <Tabs
          value={currentView}
          onChange={(_, newValue) => setCurrentView(newValue)}
        >
          <Tab label="הגשות" />
          <Tab label="סטטיסטיקות" disabled={selectedFormId === 'all'} />
        </Tabs>
      </Box>

      {currentView === 0 ? renderSubmissionsList() : renderStats()}

      <Dialog
        open={statsDialogOpen}
        onClose={() => setStatsDialogOpen(false)}
        maxWidth="md"
        fullWidth
        sx={{ textAlign: 'right' }}
      >
        <DialogTitle>סטטיסטיקות טופס</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            סך הכל הגשות: {currentStats?.totalSubmissions || 0}
          </Typography>
          {renderStats()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatsDialogOpen(false)}>סגור</Button>
          <Button
            onClick={() => exportToExcel()}
            startIcon={<DownloadIcon />}
            variant="contained"
          >
            ייצוא נתונים
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FormSubmissions;
