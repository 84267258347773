import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCategories,
  updateCategory,
  createCategory,
  deleteCategory,
} from '../../api/categoryApi';

// Thunk to fetch all categories
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const categories = await getCategories();
      return categories;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to update category order
export const updateCategoryThunk = createAsyncThunk(
  'categories/updateCategory',
  async (updatedCategory, { rejectWithValue }) => {
    try {
      const { id, ...categoryData } = updatedCategory;
      await updateCategory(id, categoryData);
      return updatedCategory;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to add a new category
export const addCategoryThunk = createAsyncThunk(
  'categories/addCategory',
  async (newCategory, { rejectWithValue }) => {
    try {
      const { id, ...categoryData } = newCategory;
      const createdCategory = await createCategory(categoryData);
      return createdCategory;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to delete a category
export const deleteCategoryThunk = createAsyncThunk(
  'categories/deleteCategory',
  async (category, { rejectWithValue }) => {
    try {
      await deleteCategory(category);
      return category;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
