import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MenuPage from './pages/MenuPage';
import ManagerDashboard from './pages/ManagerMenuDashboard';
import Login from './pages/Login';
import CreateEmployeeForm from './pages/CreateEmployeeForm';
import ThemeConfigPage from './components/dashboardComponents/ThemeConfigPage';
import ConfigLayout from './layouts/ConfigLayout';
import EmployeeForms from './pages/EmployeeForms';
import Reports from './pages/Reports';
import FormViewer from './components/EmployeeFormComponenents/FormViewer';
import FormSubmissions from './components/EmployeeFormComponenents/FormSubmissions';
import WorkingHoursManager from './components/dashboardComponents/WorkingHoursManager';
import ConfigurationManager from './components/dashboardComponents/ConfigurationManager';
import CouponsManagement from './components/dashboardComponents/CouponManager';
import { ProtectedRoute } from './auth';
import ManagerOrdersDashboard from './pages/ManagerOrdersDashboard';
import AccessibilityDeclaration from '../src/components/AccessibilityDeclaration';

const AppRoutes = ({ toggleTheme, handleLogin }) => {
  const useProtectedRoutes = true; // Set to true to enable ProtectedRoute

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigate to="/menu" />} />
      <Route path="/menu" element={<MenuPage toggleTheme={toggleTheme} />} />
      <Route
        path="/login"
        element={<Login onLogin={handleLogin} toggleTheme={toggleTheme} />}
      />

      {/* Orders Management Route */}
      <Route
        path="/orders"
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <ManagerOrdersDashboard />
            </ProtectedRoute>
          ) : (
            <ManagerOrdersDashboard />
          )
        }
      />

      {/* Conditionally Protected Routes */}
      <Route
        path="/dashboard"
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <ManagerDashboard toggleTheme={toggleTheme} />
            </ProtectedRoute>
          ) : (
            <ManagerDashboard toggleTheme={toggleTheme} />
          )
        }
      />
      <Route
        path="/CreateEmployeeForm"
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <CreateEmployeeForm />
            </ProtectedRoute>
          ) : (
            <CreateEmployeeForm />
          )
        }
      />

      <Route
        path="/Reports"
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          ) : (
            <Reports />
          )
        }
      />

      {/* Conditionally Protected Config Routes */}
      <Route
        path="/config"
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <ConfigLayout />
            </ProtectedRoute>
          ) : (
            <ConfigLayout />
          )
        }
      >
        <Route index element={<Navigate to="configurations" replace />} />
        <Route path="theme" element={<ThemeConfigPage />} />
        <Route path="hours" element={<WorkingHoursManager />} />
        <Route path="forms" element={<EmployeeForms />} />
        <Route path="submissions" element={<FormSubmissions />} />
        <Route path="configurations" element={<ConfigurationManager />} />
        <Route path="coupons" element={<CouponsManagement />} />
      </Route>
      <Route path="/accessibility" element={<AccessibilityDeclaration />} />

      {/* Conditionally Protected Form Routes */}
      <Route
        path="/forms/fill/:formId"
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <FormViewer />
            </ProtectedRoute>
          ) : (
            <FormViewer />
          )
        }
      />

      {/* Legacy Route Redirects */}
      <Route
        path="/theme-config"
        element={<Navigate to="/config/theme" replace />}
      />
      <Route path="/forms" element={<Navigate to="/config/forms" replace />} />
      <Route
        path="/forms/submissions"
        element={<Navigate to="/config/submissions" replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
