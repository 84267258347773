import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

const RadioButton = ({
  field,
  updateFieldLabel,
  updateFieldOptions,
  onDeleteField,
  onToggleRequired,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newOption, setNewOption] = useState('');
  const [editingOptionId, setEditingOptionId] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');

  const handleAddOption = () => {
    if (newOption.trim() !== '') {
      updateFieldOptions(field.id, [...field.options, newOption.trim()]);
      setNewOption('');
    }
  };

  const handleEditOption = (index, value) => {
    const newOptions = [...field.options];
    newOptions[index] = value;
    updateFieldOptions(field.id, newOptions);
  };

  const handleDeleteOption = (index) => {
    const newOptions = field.options.filter((_, i) => i !== index);
    updateFieldOptions(field.id, newOptions);
    if (selectedValue === field.options[index]) {
      setSelectedValue('');
    }
  };

  const handleStartEditing = (index) => {
    setEditingOptionId(index);
  };

  const handleFinishEditing = () => {
    setEditingOptionId(null);
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Box
      mb={2}
      sx={{
        position: 'relative',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        padding: 2,
      }}
    >
      <IconButton
        size="small"
        onClick={() => onDeleteField(field.id)}
        sx={{
          position: 'absolute',
          top: 8,
          left: 8,
          color: 'error.main',
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <Box display="flex" alignItems="center" mb={1} justifyContent="flex-end">
        <FormControlLabel
          control={
            <Switch
              checked={field.required || false}
              onChange={(e) => onToggleRequired(field.id, e.target.checked)}
              size="small"
            />
          }
          label="חובה"
          sx={{ ml: 0, mr: 2 }}
          labelPlacement="start"
        />
        {isEditing ? (
          <TextField
            value={field.label}
            onChange={(e) => updateFieldLabel(field.id, e.target.value)}
            onBlur={() => setIsEditing(false)}
            autoFocus
            size="small"
            sx={{
              '& .MuiInputBase-input': { textAlign: 'right' },
            }}
          />
        ) : (
          <>
            <IconButton size="small" onClick={() => setIsEditing(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <Typography
              variant="subtitle1"
              color={field.required ? 'error.main' : 'inherit'}
              sx={{ textAlign: 'right' }}
            >
              {field.required && (
                <ErrorIcon sx={{ ml: 1, fontSize: 20, color: 'error.main' }} />
              )}
              {field.label}
            </Typography>
          </>
        )}
      </Box>
      <RadioGroup value={selectedValue} onChange={handleRadioChange}>
        {field.options.map((option, index) => (
          <Box
            key={`${field.id}-option-${index}`}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {editingOptionId !== index && (
              <>
                <IconButton
                  size="small"
                  onClick={() => handleDeleteOption(index)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleStartEditing(index)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </>
            )}
            <FormControlLabel
              value={option}
              control={<Radio />}
              label={
                editingOptionId === index ? (
                  <TextField
                    value={option}
                    onChange={(e) => handleEditOption(index, e.target.value)}
                    onBlur={handleFinishEditing}
                    autoFocus
                    size="small"
                    sx={{
                      '& .MuiInputBase-input': { textAlign: 'right' },
                    }}
                  />
                ) : (
                  <Typography sx={{ textAlign: 'right' }}>{option}</Typography>
                )
              }
              sx={{
                ml: 0,
                mr: 1,
                flexDirection: 'row-reverse',
              }}
            />
          </Box>
        ))}
      </RadioGroup>
      <Box mt={1} display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton onClick={handleAddOption} size="small">
          <AddIcon fontSize="small" />
        </IconButton>
        <TextField
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
          placeholder="אפשרות חדשה"
          size="small"
          sx={{
            '& .MuiInputBase-input': { textAlign: 'right' },
          }}
        />
      </Box>
    </Box>
  );
};

export default RadioButton;
