import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from '../../features/orders/ordersThunk';
import { fetchOrderWithDetails } from '../../features/orders/orderDetailsThunk';
import {
  selectAllOrders,
  selectStatus,
  selectError,
} from '../../features/orders/ordersSlice';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Collapse,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SpinLoader from '../core/SpinLoader';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const Row = ({ order, formatDate, getStatusColor, getStatusText, index }) => {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const is800pxWidth = useMediaQuery('(max-width:800px)');
  const theme = useTheme();

  const handleClick = async () => {
    if (!open && !details) {
      setLoading(true);
      try {
        const result = await dispatch(fetchOrderWithDetails(order.id)).unwrap();
        setDetails(result.details || []);
      } catch (error) {
        console.error('Failed to fetch order details:', error);
        setDetails([]);
      }
      setLoading(false);
    }
    setOpen(!open);
  };

  const calculateItemTotalPrice = (item) => {
    const basePrice = Number(item.price || 0);
    const filtersPrice =
      item.selectedFilters?.reduce((total, filter) => {
        return (
          total +
          (Array.isArray(filter.optionPrice)
            ? filter.optionPrice.reduce(
                (sum, price) => sum + Number(price || 0),
                0
              )
            : Number(filter.optionPrice || 0))
        );
      }, 0) || 0;
    return basePrice + filtersPrice;
  };

  return (
    <>
      {!is800pxWidth ? (
        <>
          <TableRow
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? theme.palette.mode === 'dark'
                    ? alpha(theme.palette.background.paper, 0.2)
                    : alpha(theme.palette.grey[100], 0.9)
                  : theme.palette.mode === 'dark'
                  ? theme.palette.background.paper
                  : alpha(theme.palette.grey[200], 0.9),
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? alpha(theme.palette.action.hover, 0.1)
                    : theme.palette.action.hover,
              },
            }}
          >
            <TableCell align="right">{order.fullName}</TableCell>
            <TableCell align="right">{order.phoneNumber}</TableCell>
            <TableCell align="right">
              <Chip
                label={getStatusText(order.status)}
                color={getStatusColor(order.status)}
                size="small"
              />
            </TableCell>
            <TableCell align="right">₪{order.totalPrice.toFixed(2)}</TableCell>
            <TableCell align="right">{order.totalQuantity}</TableCell>
            <TableCell align="right">
              {order.paymentMethod === 'card'
                ? 'כרטיס אשראי'
                : order.paymentMethod}
            </TableCell>
            <TableCell align="right">
              {order.source === 'web' ? 'אתר' : order.source}
            </TableCell>
            <TableCell align="right">{`${formatDate(order.createdAt).time} - ${
              formatDate(order.createdAt).date
            }`}</TableCell>
            <TableCell align="center">
              <IconButton size="small" onClick={handleClick}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={10} style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    margin: 1,
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? theme.palette.background.paper
                        : theme.palette.background.default,
                  }}
                >
                  {loading ? (
                    <SpinLoader />
                  ) : details && details.length > 0 ? (
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right">שם פריט</TableCell>
                            <TableCell align="right">כמות</TableCell>
                            <TableCell align="right">מחיר ליחידה</TableCell>
                            <TableCell align="right">סה״כ</TableCell>
                            <TableCell align="right">תוספות</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {details.map((item, index) => {
                            const totalItemPrice =
                              calculateItemTotalPrice(item) * item.quantity;
                            const isLastRow = index === details.length - 1; // Check if it's the last row

                            return (
                              <TableRow
                                key={item.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    borderBottom: isLastRow
                                      ? 'none'
                                      : '1px solid rgba(224, 224, 224, 1)',
                                  },
                                }}
                              >
                                <TableCell align="right">
                                  {item.menuName}
                                </TableCell>
                                <TableCell align="right">
                                  {item.quantity}
                                </TableCell>
                                <TableCell align="right">
                                  ₪{calculateItemTotalPrice(item).toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  ₪{totalItemPrice.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  {item.selectedFilters
                                    ?.map((f) => `${f.filterName}: ${f.option}`)
                                    .join(', ') || '-'}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>{' '}
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography align="right">אין פרטים זמינים</Typography>
                  )}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : (
        <Paper
          sx={{
            padding: 2,
            marginBottom: 2,
            cursor: 'pointer',
            direction: 'rtl',
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.background.paper
                : theme.palette.background.default,
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? alpha(theme.palette.action.hover, 0.1)
                  : theme.palette.action.hover,
            },
          }}
          onClick={handleClick}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{order.fullName}</Typography>
            <IconButton size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
          <Typography sx={{ display: 'flex', gap: '12px' }}>
            <span style={{ fontWeight: 500 }}>תאריך:</span>
            <span>{`${formatDate(order.createdAt).date} ${
              formatDate(order.createdAt).time
            }`}</span>
          </Typography>
          <Typography sx={{ display: 'flex', gap: '12px' }}>
            <span style={{ fontWeight: 500 }}>טלפון:</span>
            <span>{order.phoneNumber}</span>
          </Typography>
          <Typography
            sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}
          >
            <span style={{ fontWeight: 500 }}>סטטוס:</span>
            <Chip
              label={getStatusText(order.status)}
              color={getStatusColor(order.status)}
              size="small"
            />
          </Typography>
          <Typography sx={{ display: 'flex', gap: '12px' }}>
            <span style={{ fontWeight: 500 }}>סכום:</span>
            <span>₪{order.totalPrice.toFixed(2)}</span>
          </Typography>
          <Typography sx={{ display: 'flex', gap: '12px' }}>
            <span style={{ fontWeight: 500 }}>כמות פריטים:</span>
            <span>{order.totalQuantity}</span>
          </Typography>
          <Typography sx={{ display: 'flex', gap: '12px' }}>
            <span style={{ fontWeight: 500 }}>אמצעי תשלום:</span>
            <span>
              {order.paymentMethod === 'card'
                ? 'כרטיס אשראי'
                : order.paymentMethod}
            </span>
          </Typography>
          <Typography sx={{ display: 'flex', gap: '12px' }}>
            <span style={{ fontWeight: 500 }}>מקור:</span>
            <span>{order.source === 'web' ? 'אתר' : order.source}</span>
          </Typography>
          <Typography sx={{ display: 'flex', gap: '12px' }}>
            <span style={{ fontWeight: 500 }}>תאריך:</span>
            <span>{`${formatDate(order.createdAt).time} - ${
              formatDate(order.createdAt).date
            }`}</span>
          </Typography>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 1 }}>
              {loading ? (
                <SpinLoader />
              ) : details && details.length > 0 ? (
                details.map((item) => (
                  <Box
                    key={item.id}
                    sx={{ mb: 1, borderTop: '1px solid ', pt: 1 }}
                  >
                    <Typography>שם פריט: {item.menuName}</Typography>
                    <Typography>כמות: {item.quantity}</Typography>
                    <Typography>
                      מחיר ליחידה: ₪{calculateItemTotalPrice(item).toFixed(2)}
                    </Typography>
                    <Typography>
                      סה״כ: ₪
                      {(calculateItemTotalPrice(item) * item.quantity).toFixed(
                        2
                      )}
                    </Typography>
                    <Typography>
                      תוספות:{' '}
                      {item.selectedFilters
                        ?.map((f) => `${f.filterName}: ${f.option}`)
                        .join(', ') || '-'}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography align="right">אין פרטים זמינים</Typography>
              )}
            </Box>
          </Collapse>
        </Paper>
      )}
    </>
  );
};

const OrdersTable = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectAllOrders);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const is800pxWidth = useMediaQuery('(max-width:800px)');
  const theme = useTheme();

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return {
      time: date.toLocaleString('he-IL', {
        hour: '2-digit',
        minute: '2-digit',
      }),
      date: date.toLocaleString('he-IL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    };
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'completed':
        return 'success';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'pending':
        return 'ממתין';
      case 'completed':
        return 'הושלם';
      case 'cancelled':
        return 'בוטל';
      default:
        return status;
    }
  };

  if (status === 'loading') return <SpinLoader />;
  if (error)
    return (
      <Typography color="error" align="center">
        {error}
      </Typography>
    );

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: 'right' }}>
        היסטוריית הזמנות
      </Typography>

      {!is800pxWidth ? (
        <TableContainer
          component={Paper}
          sx={{
            direction: 'rtl',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.background.paper
                : theme.palette.background.default,
            '& .MuiTableCell-head': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? alpha(theme.palette.common.white, 0.05)
                  : alpha(theme.palette.common.black, 0.05),
              fontWeight: 'bold',
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right">שם לקוח</TableCell>
                <TableCell align="right">טלפון</TableCell>
                <TableCell align="right">סטטוס</TableCell>
                <TableCell align="right">סכום</TableCell>
                <TableCell align="right">כמות פריטים</TableCell>
                <TableCell align="right">אמצעי תשלום</TableCell>
                <TableCell align="right">מקור</TableCell>
                <TableCell align="right">תאריך</TableCell>
                <TableCell width="48px" />
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <Row
                  key={order.id}
                  order={order}
                  formatDate={formatDate}
                  getStatusColor={getStatusColor}
                  getStatusText={getStatusText}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
          {orders.map((order) => (
            <Row
              key={order.id}
              order={order}
              formatDate={formatDate}
              getStatusColor={getStatusColor}
              getStatusText={getStatusText}
              style={{
                '@media (max-width: 768px)': {
                  '& .key-value-pair': {
                    display: 'flex',
                    gap: '12px', // Adds 3 spaces worth of spacing
                    // Alternative: marginLeft: '12px' or paddingLeft: '12px'
                  },
                },
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default OrdersTable;
