import React, { useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SearchInput from './SearchInput';
import { useMediaQuery, useTheme } from '@mui/material';

function ResponsiveAppBar({
  categories,
  activeCategory,
  onCategoryClick,
  onSearch,
  searchTerm,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const categoryRefs = useRef([]);
  const navbarRef = useRef(null); // Ref for the entire navbar container

  // Scroll the active category into view
  useEffect(() => {
    const activeIndex = categories.findIndex(
      (category) => category.id === activeCategory
    );

    if (activeIndex !== -1 && categoryRefs.current[activeIndex]) {
      const activeCategoryButton = categoryRefs.current[activeIndex];

      // Calculate the required scroll position to center the active button
      const buttonLeft = activeCategoryButton.offsetLeft;
      const buttonWidth = activeCategoryButton.offsetWidth;
      const containerWidth = navbarRef.current.offsetWidth;

      const scrollTo = buttonLeft - containerWidth / 2 + buttonWidth / 2;

      // Smooth scroll to the calculated position
      navbarRef.current.scrollTo({
        left: scrollTo,
        behavior: 'smooth',
      });
    }
  }, [activeCategory, categories]);

  const sortedCategories = categories.sort(
    (a, b) => a.displayOrder - b.displayOrder
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.background.paper
            : '#f3f3f3',
        mb: 2,
        direction: 'rtl',
        boxShadow: theme.palette.mode === 'dark' ? 'none' : undefined,
        borderBottom:
          theme.palette.mode === 'dark'
            ? `1px solid ${theme.palette.divider}`
            : 'none',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            ref={navbarRef} // Attach the navbarRef to the Box that holds the category buttons
            sx={{
              flexGrow: 1,
              display: 'flex',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': { display: 'none' },
              alignItems: 'center',
            }}
          >
            {sortedCategories &&
              sortedCategories.map((category, index) => (
                <Button
                  key={category.id}
                  ref={(el) => (categoryRefs.current[index] = el)}
                  onClick={() => onCategoryClick(category.id)}
                  sx={{
                    display: 'inline-flex',
                    mx: index === 0 ? 0 : 0.5,
                    ml: index === sortedCategories.length - 1 ? 0 : 0.5,
                    color:
                      activeCategory === category.id
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    whiteSpace: 'nowrap',
                    px: 2,
                    textAlign: 'center',
                    minWidth: 'fit-content',
                    borderTop: 'unset',
                    borderBottom:
                      activeCategory === category.id ? '2px solid' : 'none',
                    borderColor: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <Typography variant="navbar">{category.name}</Typography>
                </Button>
              ))}
          </Box>
          {!isMobile && (
            <SearchInput searchTerm={searchTerm} onSearch={onSearch} />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
