/**
 * @param darkModeAccessibilityTime - The time in hours that the dark mode cookie will be accessible.
 * @param lightTimeStart - The time in 24-hour format that the light mode will start in case the api call fails.
 * @param lightTimeEnd - The time in 24-hour format that the light mode will end in case the api call fails.
 * @param notInStockMessage - The message to show when a product is out of stock.
 * @param israelLatitude - The latitude used for the sunrise and sunset api call.
 * @param israelLongitude - The longitude used for the sunrise and sunset api call.
 * 
 */
export const darkModeAccessibilityTime = 12;
export const lightTimeStart = "06:00";
export const lightTimeEnd = "18:00";
export const notInStockMessage = 'אזל במלאי';
export const israelLatitude = 32.0853;
export const israelLongitude = 34.7818;