import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWorkingHoursStatus } from '../features/workingHours/workingHoursSlice';
import { fetchWorkingHours } from '../features/workingHours/workingHoursThunk';
import { Outlet, useLocation, Link } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
  Drawer,
} from '@mui/material';
import DashboardNavbar from '../components/dashboardComponents/DashboardNavbar';
import MenuIcon from '@mui/icons-material/Menu';
import {
  fetchConfiguration,
  selectConfigurationStatus,
} from '../features/configuration/configurationSlice';

// העברת configRoutes מחוץ לקומפוננטה
const configRoutes = [
  { path: 'configurations', label: 'הגדרות עסק' },
  { path: 'theme', label: 'עיצוב אתר' },
  { path: 'hours', label: 'עריכת שעות פעילות' },
  { path: 'coupons', label: 'ניהול קופונים' },
  { path: 'forms', label: 'ניהול טפסים' },
  { path: 'submissions', label: 'טפסים שהוגשו' },
];

const ConfigLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState('הגדרות אתר');
  const dispatch = useDispatch();
  const workingHoursStatus = useSelector(selectWorkingHoursStatus);
  const configurationStatus = useSelector(selectConfigurationStatus);

  useEffect(() => {
    if (workingHoursStatus === 'idle') {
      dispatch(fetchWorkingHours());
    }
  }, [workingHoursStatus, dispatch]);

  useEffect(() => {
    if (configurationStatus === 'idle') {
      console.log('fetching configuration');
      dispatch(fetchConfiguration());
    }
  }, [configurationStatus, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Update page title based on the current path
  useEffect(() => {
    const currentRoute = configRoutes.find((route) =>
      location.pathname.includes(route.path)
    );
    if (currentRoute) {
      setPageTitle(currentRoute.label);
    } else {
      setPageTitle('הגדרות אתר');
    }
  }, [location.pathname]);

  const sidebar = (
    <Box
      sx={{
        width: 240,
        bgcolor: 'background.paper',
      }}
    >
      {configRoutes.map(({ path, label }) => (
        <Button
          key={path}
          component={Link}
          to={`/config/${path}`}
          fullWidth
          onClick={isMobile ? handleDrawerToggle : undefined}
          sx={{
            padding: 2,
            textAlign: 'right',
            fontSize: '1.1rem', // Added this line to make text bigger
            bgcolor: location.pathname.includes(path)
              ? 'action.selected'
              : 'transparent',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );

  return (
    <Box>
      <DashboardNavbar />

      {/* Mobile Menu Button */}
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            m: 2,
            position: 'fixed',
            top: '64px',
            right: '10px',
            zIndex: 1,
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          minHeight: 'calc(100vh - 64px)',
        }}
      >
        {/* Main content */}
        <Box
          sx={{
            flex: 1,
            p: 3,
            marginRight: { md: '240px' },
          }}
        >
          <h1 style={{ textAlign: 'right', margin: '0 1rem 1rem 1rem' }}>
            {pageTitle}
          </h1>
          <Outlet />
        </Box>

        {/* Desktop Sidebar */}
        {!isMobile && (
          <Box
            component="nav"
            sx={{
              width: 240,
              flexShrink: 0,
              position: 'fixed',
              right: 0,
              top: 64,
              height: 'calc(100vh - 64px)',
              borderLeft: 1,
              borderColor: 'divider',
              bgcolor: 'background.paper',
              overflowY: 'auto',
            }}
          >
            {sidebar}
          </Box>
        )}

        {/* Mobile Sidebar */}
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              width: 240,
              top: '64px',
              height: 'calc(100% - 64px)',
              bgcolor: 'background.paper',
            },
          }}
        >
          {sidebar}
        </Drawer>
      </Box>
    </Box>
  );
};

export default ConfigLayout;
