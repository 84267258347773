import { createAsyncThunk } from '@reduxjs/toolkit';
import * as authApi from '../../api/authApi';

export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await authApi.login(credentials);
      return response;
    } catch (err) {
      // Handle Axios error responses
      if (err.response) {
        // Server responded with error
        return rejectWithValue(
          err.response.data?.error ||
            err.response.data?.message ||
            'Server error'
        );
      } else if (err.request) {
        // Request made but no response
        return rejectWithValue('Network Error');
      } else {
        // Other errors
        return rejectWithValue(err.message || 'התחברות נכשלה');
      }
    }
  }
);

export const logoutUser = createAsyncThunk('auth/logout', async () => {
  await authApi.logout();
});

export const checkAuthStatus = createAsyncThunk(
  'auth/check',
  async (_, { rejectWithValue }) => {
    try {
      const response = await authApi.checkAuth();
      if (!response.isAuthenticated) {
        return rejectWithValue('שגיאה בחיבור');
      }
      return response;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

/* export const getProfileInfo = createAsyncThunk('auth/getProfile', async () => {
  const response = await authApi.getProfile();
  return response;
});

export const updateProfileInfo = createAsyncThunk(
  'auth/updateProfile',
  async ({ userId, userData }) => {
    const response = await authApi.updateProfile(userId, userData);
    return response;
  }
); */
