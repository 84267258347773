import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectConfiguration,
  selectConfigurationStatus 
} from '../features/configuration/configurationSlice';
import { fetchConfiguration } from '../features/configuration/configurationThunk';
import { 
  selectWorkingHours,
  selectWorkingHoursStatus 
} from '../features/workingHours/workingHoursSlice';
import { fetchWorkingHours } from '../features/workingHours/workingHoursThunk';

export const useInitialData = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const configurations = useSelector(selectConfiguration);
  const workingHours = useSelector(selectWorkingHours);
  const configStatus = useSelector(selectConfigurationStatus);
  const workingHoursStatus = useSelector(selectWorkingHoursStatus);

  const isLoading = configStatus === 'loading' || workingHoursStatus === 'loading';

  useEffect(() => {
    const fetchInitialData = async () => {
      setError(null);

      try {
        const fetchPromises = [];

        if (configStatus === 'idle') {
          fetchPromises.push(dispatch(fetchConfiguration()).unwrap());
        }

        if (workingHoursStatus === 'idle') {
          fetchPromises.push(dispatch(fetchWorkingHours()).unwrap());
        }

        if (fetchPromises.length > 0) {
          await Promise.all(fetchPromises);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError(error);
      }
    };

    fetchInitialData();
  }, [dispatch, configStatus, workingHoursStatus]); 

  return {
    isLoading,
    error,
    isConfigLoaded: configStatus === 'succeeded',
    isWorkingHoursLoaded: workingHoursStatus === 'succeeded',
    configurations,
    workingHours,
  };
};
