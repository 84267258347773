import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import FilterItem from './FilterItem';
import './FilterOrderPopup.css';

const FilterOrderPopup = ({ open, onClose, filters, onUpdateFilters }) => {
  const [localFilters, setLocalFilters] = useState([]);
  const [initialFilters, setInitialFilters] = useState([]);

  useEffect(() => {
    if (open) {
      const copiedFilters = JSON.parse(JSON.stringify(filters));
      setLocalFilters(copiedFilters);
      setInitialFilters(copiedFilters);
    }
  }, [open, filters]);

  const moveFilter = (fromIndex, toIndex) => {
    const updatedFilters = [...localFilters];
    const [movedFilter] = updatedFilters.splice(fromIndex, 1);
    updatedFilters.splice(toIndex, 0, movedFilter);

    updatedFilters.forEach((filter, index) => {
      filter.displayOrder = index + 1;
    });

    setLocalFilters(updatedFilters);
  };

  const handleSave = () => {
    // Create an object with filter IDs as keys and updated filters as values
    const updatedFiltersObj = localFilters.reduce((acc, filter) => {
      acc[filter.id] = filter;
      return acc;
    }, {});

    onUpdateFilters(updatedFiltersObj);
    onClose();
  };

  const handleCancel = () => {
    setLocalFilters(JSON.parse(JSON.stringify(initialFilters)));
    onClose();
  };

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          width: '400px',
          height: '400px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        עריכת סדר תצוגת המסננים
      </DialogTitle>
      <DialogContent style={{ flex: '1 1 auto', overflowY: 'auto' }}>
        <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
          {localFilters.map((filter, index) => (
            <FilterItem
              key={filter.id}
              filter={filter}
              index={index}
              moveFilter={moveFilter}
            />
          ))}
        </DndProvider>
      </DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 'auto',
          padding: '16px',
        }}
      >
        <Button onClick={handleCancel} variant="outlined">
          ביטול
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          שמירה
        </Button>
      </div>
    </Dialog>
  );
};

export default FilterOrderPopup;
