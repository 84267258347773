import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/coupons';
const local_URL = 'http://localhost:3001/api/coupons';
/* eslint-enable no-unused-vars */

const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

// Fetch all coupons
export const getCoupons = async () => {
  const response = await api.get(BASE_URL);
  return response.data;
};

// Fetch a single coupon by ID
export const getCoupon = async (id) => {
  const response = await api.get(BASE_URL + `/${id}`);
  return response.data;
};

// Create a new coupon
export const createCoupon = async (coupon) => {
  const response = await api.post(BASE_URL, coupon);
  return response.data;
};

// Update a coupon
export const updateCoupon = async (id, coupon) => {
  const response = await api.put(BASE_URL + `/${id}`, coupon);
  return response.data;
};

// Delete a coupon
export const deleteCoupon = async (id) => {
  const response = await api.delete(BASE_URL + `/${id}`);
  return response.data;
};
