import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  confirmText,
  isShowDeleteBtn = true,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box style={{ direction: 'rtl' }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{confirmText || 'ביטול'}</Button>
          {isShowDeleteBtn && (
            <Button onClick={onConfirm} color="primary">
              מחיקה
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
