import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const calculateItemTotalPrice = (item) => {
  const basePrice = item.price;
  const selectedOptionsPrice = item.selectedValues.reduce((total, filter) => {
    if (Array.isArray(filter.option)) {
      return (
        total +
        (Array.isArray(filter.optionPrice)
          ? filter.optionPrice.reduce(
              (sum, price) => sum + parseFloat(price),
              0
            )
          : 0)
      );
    } else {
      return total + parseFloat(filter.optionPrice || 0);
    }
  }, 0);
  return (basePrice + selectedOptionsPrice) * item.quantity;
};

const saveToLocalStorage = (state) => {
  localStorage.setItem('cartItems', JSON.stringify(state.items));
};

const calculateTotals = (items) => {
  const totalQuantity = items.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = Number(
    items.reduce((total, item) => total + item.totalPrice, 0).toFixed(2)
  );
  return { totalQuantity, totalPrice };
};

const generateFilterBasedId = (item) => {
  // Create a copy of selectedValues and sort each filter's options alphabetically if they are arrays of strings
  const sortedSelectedValues = [...item.selectedValues]
    .map((filter) => {
      // Check if filter.option is an array of strings
      if (
        Array.isArray(filter.option) &&
        filter.option.every((opt) => typeof opt === 'string')
      ) {
        // Create a copy of the option array and sort it alphabetically
        const sortedOption = [...filter.option].sort((a, b) =>
          a.localeCompare(b)
        );
        return {
          filterId: filter.filterId,
          filterName: filter.filterName,
          option: sortedOption,
        };
      }
      // Return the filter without optionPrice if it's not an array of strings
      return {
        filterId: filter.filterId,
        filterName: filter.filterName,
        option: filter.option,
      };
    })
    // Sort the filters by filterName alphabetically
    .sort((a, b) => a.filterName.localeCompare(b.filterName));

  // Generate a unique ID by combining the item ID and the sorted selected values
  return `${item.id}_${JSON.stringify(sortedSelectedValues)}`;
};

const initialState = {
  items: JSON.parse(localStorage.getItem('cartItems')) || [],
  totalQuantity: 0,
  totalPrice: 0,
};

if (initialState.items.length > 0) {
  const totals = calculateTotals(initialState.items);
  initialState.totalQuantity = totals.totalQuantity;
  initialState.totalPrice = totals.totalPrice;
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const filterBasedId = generateFilterBasedId(newItem);
      const existingItem = state.items.find(
        (item) => item.filterBasedId === filterBasedId
      );

      if (existingItem) {
        existingItem.quantity += newItem.quantity;
        existingItem.totalPrice = calculateItemTotalPrice(existingItem);
      } else {
        newItem.uniqueId = uuidv4();
        newItem.filterBasedId = filterBasedId;
        newItem.totalPrice = calculateItemTotalPrice(newItem);
        state.items.push(newItem);
      }
      state.totalQuantity = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.totalPrice,
        0
      );
      saveToLocalStorage(state);
    },
    updateCartItem: (state, action) => {
      const updatedItem = action.payload;
      const filterBasedId = generateFilterBasedId(updatedItem);
      const existingItemWithSameFilters = state.items.find(
        (item) =>
          item.filterBasedId === filterBasedId &&
          item.uniqueId !== updatedItem.uniqueId
      );
      const existingItemWithSameUniqueId = state.items.find(
        (item) => item.uniqueId === updatedItem.uniqueId
      );

      if (existingItemWithSameFilters) {
        // Merge the updated item into the existing item with the same filters
        existingItemWithSameFilters.quantity += updatedItem.quantity;
        existingItemWithSameFilters.totalPrice +=
          calculateItemTotalPrice(updatedItem);
        // Remove the updated item from the state
        state.items = state.items.filter(
          (item) => item.uniqueId !== updatedItem.uniqueId
        );
      } else if (existingItemWithSameUniqueId) {
        // Update the existing item with the same unique ID
        existingItemWithSameUniqueId.selectedValues =
          updatedItem.selectedValues;
        existingItemWithSameUniqueId.quantity = updatedItem.quantity;
        existingItemWithSameUniqueId.totalPrice =
          calculateItemTotalPrice(updatedItem);
        existingItemWithSameUniqueId.filterBasedId = filterBasedId; // Update the filter-based ID
      } else {
        // If no matching item is found, add the updated item as a new item
        updatedItem.uniqueId = uuidv4(); // Generate a unique identifier
        updatedItem.filterBasedId = filterBasedId;
        updatedItem.totalPrice = calculateItemTotalPrice(updatedItem);
        state.items.push(updatedItem);
      }

      state.totalQuantity = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.totalPrice,
        0
      );
      saveToLocalStorage(state);
    },
    removeFromCart: (state, action) => {
      const uniqueId = action.payload.uniqueId;
      const existingItem = state.items.find(
        (item) => item.uniqueId === uniqueId
      );

      if (existingItem) {
        state.items = state.items.filter((item) => item.uniqueId !== uniqueId);
        state.totalQuantity -= existingItem.quantity;
        state.totalPrice = state.items.reduce(
          (total, item) => total + item.totalPrice,
          0
        );
      }
      saveToLocalStorage(state);
    },
    decreaseFromCart: (state, action) => {
      const uniqueId = action.payload.uniqueId;
      const existingItem = state.items.find(
        (item) => item.uniqueId === uniqueId
      );

      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity -= 1;
        existingItem.totalPrice = calculateItemTotalPrice(existingItem);
        state.totalQuantity -= 1;
        state.totalPrice = state.items.reduce(
          (total, item) => total + item.totalPrice,
          0
        );
      }
      saveToLocalStorage(state);
    },
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      localStorage.removeItem('cartItems'); // Clear localStorage
    },
  },
});

export const {
  addToCart,
  updateCartItem,
  removeFromCart,
  decreaseFromCart,
  clearCart,
} = cartSlice.actions;

export const selectTotalQuantity = (state) => state.cart.totalQuantity;
export const selectTotalPrice = (state) => state.cart.totalPrice;
export const selectCartItems = (state) => state.cart.items;

export default cartSlice.reducer;
