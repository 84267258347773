import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setForms,
  setSubmissions,
  setLoading,
  setError,
  removeForm,
  removeLinkedSubmissions,
} from './formsSlice';

// טעינת כל הנתונים
export const loadFormsData = createAsyncThunk(
  'forms/loadData',
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const savedForms = JSON.parse(
        localStorage.getItem('employee_forms') || '[]'
      );
      const savedSubmissions = JSON.parse(
        localStorage.getItem('form_submissions') || '[]'
      );

      dispatch(setForms(savedForms));
      dispatch(setSubmissions(savedSubmissions));
      dispatch(setError(null));

      return { forms: savedForms, submissions: savedSubmissions };
    } catch (error) {
      dispatch(setError('Failed to load forms data'));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// מחיקת טופס
export const deleteForm = createAsyncThunk(
  'forms/deleteForm',
  async ({ formId, deleteSubmissions = false }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      // מחיקת הטופס
      dispatch(removeForm(formId));

      // אם נבחר למחוק גם הגשות
      if (deleteSubmissions) {
        dispatch(removeLinkedSubmissions(formId));
      }

      // שמירה ב-localStorage
      const state = getState().forms;
      localStorage.setItem('employee_forms', JSON.stringify(state.forms));

      if (deleteSubmissions) {
        localStorage.setItem(
          'form_submissions',
          JSON.stringify(state.submissions)
        );
      }

      dispatch(setError(null));
      return { formId, success: true };
    } catch (error) {
      dispatch(setError('Failed to delete form'));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// מחיקת הגשה
export const deleteSubmission = createAsyncThunk(
  'forms/deleteSubmission',
  async (submissionId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const state = getState().forms;
      const updatedSubmissions = state.submissions.filter(
        (s) => s.id !== submissionId
      );

      dispatch(setSubmissions(updatedSubmissions));
      localStorage.setItem(
        'form_submissions',
        JSON.stringify(updatedSubmissions)
      );

      dispatch(setError(null));
      return { submissionId, success: true };
    } catch (error) {
      dispatch(setError('Failed to delete submission'));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// הוספת טופס חדש
export const addNewForm = createAsyncThunk(
  'forms/addForm',
  async (newForm, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const state = getState().forms;
      const updatedForms = [...state.forms, newForm];

      dispatch(setForms(updatedForms));
      localStorage.setItem('employee_forms', JSON.stringify(updatedForms));

      dispatch(setError(null));
      return { form: newForm, success: true };
    } catch (error) {
      dispatch(setError('Failed to add form'));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// הוספת הגשה חדשה
export const addNewSubmission = createAsyncThunk(
  'forms/addSubmission',
  async (newSubmission, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const state = getState().forms;
      const updatedSubmissions = [...state.submissions, newSubmission];

      dispatch(setSubmissions(updatedSubmissions));
      localStorage.setItem(
        'form_submissions',
        JSON.stringify(updatedSubmissions)
      );

      dispatch(setError(null));
      return { submission: newSubmission, success: true };
    } catch (error) {
      dispatch(setError('Failed to add submission'));
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }
);
