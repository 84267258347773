// src/features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  loginUser,
  logoutUser,
  checkAuthStatus,
 // getProfileInfo,
  //updateProfileInfo,
} from './authThunk';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isAuthenticated: false,
    status: 'idle', // idle, loading, succeeded, failed
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'An error occurred';
      })
      // Logout
      .addCase(logoutUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = 'idle';
        state.isAuthenticated = false;
        state.error = null;
      })
      // Check Auth
      .addCase(checkAuthStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = action.payload.isAuthenticated;
        state.error = null;
      })
      .addCase(checkAuthStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.isAuthenticated = false;
        state.error = action.payload;
      })
      // Profile
/*       .addCase(getProfileInfo.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateProfileInfo.fulfilled, (state, action) => {
        state.user = action.payload;
      }); */
  },
});

// Basic selector
const selectAuthState = (state) => state.auth;

// Memoized selectors

export const selectIsAuthenticated = createSelector(
  [selectAuthState],
  (authState) => authState.isAuthenticated
);

export const selectAuthStatus = createSelector(
  [selectAuthState],
  (authState) => authState.status
);

export const selectAuthError = createSelector(
  [selectAuthState],
  (authState) => authState.error
);

// Export actions and thunks
export {
  loginUser,
  logoutUser,
  checkAuthStatus,
 // getProfileInfo,
// updateProfileInfo,
};
export const { clearError } = authSlice.actions;
export default authSlice.reducer;
