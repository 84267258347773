import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const DeletionManager = ({
  item,
  title = 'אישור מחיקה',
  description,
  onDelete,
  onDeleteLinked,
  linkedItemsText,
  deletingText = 'מוחק...',
  children,
  showFeedback = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteLinked, setDeleteLinked] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await onDelete(item);

      if (deleteLinked && onDeleteLinked) {
        await onDeleteLinked(item);
      }

      if (showFeedback) {
        setSnackbar({
          open: true,
          message: 'הפריט נמחק בהצלחה',
          severity: 'success',
        });
      }
      setIsOpen(false);
    } catch (error) {
      console.error('Error deleting item:', error);

      if (showFeedback) {
        setSnackbar({
          open: true,
          message: 'שגיאה במחיקת הפריט',
          severity: 'error',
        });
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const handleClose = () => {
    if (!isDeleting) {
      setIsOpen(false);
      setDeleteLinked(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        dir="rtl"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          {onDeleteLinked && linkedItemsText && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={deleteLinked}
                  onChange={(e) => setDeleteLinked(e.target.checked)}
                  disabled={isDeleting}
                />
              }
              label={linkedItemsText}
              sx={{ mt: 2, display: 'block' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isDeleting}>
            ביטול
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={20} /> : null}
          >
            {isDeleting ? deletingText : 'מחק'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DeletionManager;
