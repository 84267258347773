import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  Link as MuiLink,
} from '@mui/material';

const AccessibilityDeclaration = () => {
  return (
    <Box
      sx={{
        p: 3,
        direction: 'rtl',
        textAlign: 'right',
        fontFamily: 'Arial, sans-serif',
        lineHeight: 1.6,
      }}
    >
      <Typography variant="h4" gutterBottom>
        הצהרת נגישות
      </Typography>

      <Typography variant="h6" gutterBottom>
        מבוא
      </Typography>
      <Typography paragraph>
        חברת <strong>[שם החברה שלך]</strong> עושה את מירב המאמצים ומשקיעה משאבים
        רבים על מנת לספק לכל לקוחותיה שירות שוויוני, מכובד, נגיש ומקצועי. אנו
        רואים חשיבות רבה במתן שירותים ותוכן המותאמים לאנשים עם מוגבלויות מתוך
        מחויבות חברתית ומוסרית ליצירת מרחב שוויוני ונגיש.
      </Typography>
      <Typography paragraph>
        הצהרה זו מתייחסת למערכת ההזמנות שלנו ולשירותים הדיגיטליים הנלווים לה.
      </Typography>

      <Typography variant="h6" gutterBottom>
        עמידה בתקנים
      </Typography>
      <Typography paragraph>
        מערכת ההזמנות שלנו עומדת בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות
        (התאמות נגישות לשירות), התשע"ג 2013. ההתאמות בוצעו בהתאם לתקן הישראלי
        (ת"י 5568) ולמסמך WCAG 2.1 ברמה AA.
      </Typography>

      <Typography variant="h6" gutterBottom>
        תכונות נגישות במערכת
      </Typography>
      <List>
        <ListItem>
          <Typography>
            <strong>תפריט נגישות:</strong> המערכת כוללת תפריט נגישות המאפשר
            התאמות כגון הגדלת טקסט, שינוי ניגודיות, הדגשת קישורים והדגשת כותרות.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>ניווט מקלדת:</strong> ניתן להשתמש במערכת באמצעות מקלדת בלבד.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>תמיכה בקוראי מסך:</strong> המערכת מותאמת לשימוש בתוכנות
            קוראי מסך כגון NVDA ו-JAWS.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>עיצוב קריא:</strong> שימוש בפונטים ברורים והתאמות לעיוורי
            צבעים.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        הסדרי נגישות פיזיים
      </Typography>
      <Typography paragraph>
        חברת <strong>[שם העסק או הלקוח]</strong> אינה מקבלת קהל.
      </Typography>
      <Typography paragraph>
        הצהרת נגישות זו מתייחסת למערכת ההזמנות והשירותים הדיגיטליים בלבד.
      </Typography>
      <Typography paragraph>
        לפרטים נוספים על שירותי נגישות בבית העסק, יש ליצור קשר ישירות עם בית
        העסק.
      </Typography>

      <Typography variant="h6" gutterBottom>
        אחריות השימוש וההנגשה
      </Typography>
      <Typography paragraph>
        אחריות ההנגשה של המערכת והתכנים שלה חלה על החברה ומנהליה. כמו כן, רכיבים
        ושירותים של צד שלישי (לדוגמה: פייסבוק, יוטיוב, צ'אטים חיצוניים) עשויים
        להוות אתגרי נגישות שאין ביכולתנו לשלוט עליהם.
      </Typography>
      <Typography paragraph>
        להלן דוגמאות למדיניות הנגישות של צדדים שלישיים:
      </Typography>
      <List>
        <ListItem>
          <MuiLink
            href="https://www.facebook.com/help/accessibility"
            target="_blank"
          >
            מדיניות הנגישות של פייסבוק
          </MuiLink>
        </ListItem>
        <ListItem>
          <MuiLink href="https://www.google.com/accessibility/" target="_blank">
            מדיניות הנגישות של יוטיוב
          </MuiLink>
        </ListItem>
        <ListItem>
          <MuiLink
            href="https://www.instagram.com/accessibility/"
            target="_blank"
          >
            מדיניות הנגישות של אינסטגרם
          </MuiLink>
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        דרכי פנייה לשיפור ולמשוב בנושא נגישות
      </Typography>
      <Typography paragraph>
        אנו מחויבים לשיפור מתמיד של הנגישות במערכת ובשירותים שלנו. במידה ונתקלתם
        בקושי או בתקלה, נשמח שתפנו אלינו:
      </Typography>
      <List>
        <ListItem>
          <Typography>
            <strong>רכז נגישות:</strong> [שם רכז הנגישות, אם קיים]
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>דוא"ל:</strong> [כתובת דוא"ל]
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>טלפון:</strong> [מספר טלפון]
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        עדכון הצהרה
      </Typography>
      <Typography paragraph>תאריך עדכון אחרון: [תאריך].</Typography>
    </Box>
  );
};

export default AccessibilityDeclaration;
