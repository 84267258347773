import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MenuItemPopup from './MenuItemPopup';
import { selectConfiguration } from '../features/configuration/configurationSlice';
import { useSelector } from 'react-redux';
import * as globals from '../utils/globals';

const MenuItem = ({ item }) => {
  const configuration = useSelector(selectConfiguration)[0];
  const [popupOpen, setPopupOpen] = useState(false);
  const theme = useTheme();

  // Get the current theme mode
  const isDarkMode = theme.palette.mode === 'dark';

  // Define overlay color based on theme
  const overlayColor = isDarkMode
    ? 'rgba(255, 255, 255, 0.3)' // lighter overlay for dark mode
    : 'rgba(0, 0, 0, 0.3)'; // darker overlay for light mode

  const handleClick = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const isAvailable = item.isAvailable;
  const manageStock = configuration.manageStock;
  const quantityInStock = item.quantityInStock;

  const shouldDisplayRedMessage =
    !isAvailable || (manageStock && quantityInStock === 0);

  // Get inverse colors based on current theme
  const ropeColor = theme.palette.mode === 'dark' ? '#ffffff' : '#444444';
  const dotColor = theme.palette.mode === 'dark' ? '#ffffff' : '#4c4c4c';

  return (
    <>
      <Card
        style={{
          height: 'auto',
          borderRadius: '10px',
          boxShadow: 'rgba(0, 0, 0, 0.1) 5px 5px 20px',
          width: '100%',
          opacity: shouldDisplayRedMessage ? 0.85 : 1,
          position: 'relative',
          overflow: 'visible',
          cursor: shouldDisplayRedMessage ? 'not-allowed' : 'pointer',
        }}
        onClick={handleClick}
      >
        {/* Add an overlay div when out of stock */}
        {shouldDisplayRedMessage && (
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: overlayColor,
              zIndex: 1,
              borderRadius: '10px',
              pointerEvents: 'none', // This ensures the overlay doesn't interfere with clicks
            }}
          />
        )}

        {/* Out of stock message - moved above the overlay to keep it visible */}
        {shouldDisplayRedMessage && (
          <Box
            style={{
              position: 'absolute',
              top: '75px',
              right: 'clamp(70px,30vw,120px)',
              padding: '0.25em 1em 0.5em',
              backgroundColor: '#d10000',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: '4px solid #fff',
              borderRadius: '15px',
              fontSize: '1.1rem',
              fontWeight: 'bold',
              boxShadow:
                'inset 0 1px 1px rgba(0, 0, 0, 0.4), 0 5px 10px rgba(0, 0, 0, 0.4)',
              zIndex: 2, // Increased z-index to appear above the overlay
              transform: 'rotate(5deg)',
              transformOrigin: '50% -65px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                border: `2px dotted`,
                borderColor: `${ropeColor}`,
                borderBottom: 'none',
                borderLeft: 'none',
                width: '50px',
                height: '50px',
                top: '-31px',
                left: '73%',
                marginLeft: '-50px',
                transform: 'rotate(-45deg)',
                borderRadius: '0 5px 0 0',
              }}
            />
            <div
              style={{
                position: 'absolute',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                top: '-47px',
                left: '50%',
                marginLeft: '-4px',
                background: dotColor,
              }}
            />
            {globals.notInStockMessage}
          </Box>
        )}
        <CardContent
          style={{
            padding: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            spacing={0}
            alignItems="stretch"
            justifyContent="space-between"
            style={{ height: '100%' }}
          >
            <Grid
              size={{ xs: 7 }}
              style={{
                paddingRight: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ flexGrow: 1, marginLeft: '0.7rem' }}>
                <Typography variant="menuName" mb={0}>
                  {item.name}
                </Typography>
                <Typography
                  variant="menuDesc"
                  style={{
                    wordWrap: 'break-word',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    lineHeight: 1.2,
                  }}
                >
                  {item.description}
                </Typography>
              </div>
              <Typography variant="menuPrice" color="primary" mb={1}>
                {`₪${item.price.toFixed(2)}`}
              </Typography>
            </Grid>
            {item.image && (
              <Grid
                size={{ xs: 5 }}
                style={{ overflow: 'hidden', height: '120px' }}
              >
                <img
                  src={item.image}
                  alt={`צילום של ${item.name}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '10px',
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <MenuItemPopup
        open={popupOpen}
        onClose={handleClose}
        item={item}
        shouldDisplayRedMessage={shouldDisplayRedMessage}
      />
    </>
  );
};

export default MenuItem;
